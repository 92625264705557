import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from 'src/environments/environment';
import { SpecialRoleService } from 'src/app/services/special-role.service';
import { DisciplinesService } from 'src/app/services/disciplines.service';

interface Sort {
  value: string;
  viewValue: string;
}
interface Filter {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
 })
export class FilterComponent implements OnInit {
  @Output() usersEvent = new EventEmitter<any>();
  @Input() users: any;
  sort: Sort[] = [
    {value: 'name', viewValue: 'Name'},
    {value: 'company', viewValue: 'Company'},
    {value: 'discipline', viewValue: 'Discipline'},
    {value: 'special_role', viewValue: 'Special Role'},
  ];
  filter: Filter[] = [
    {value: 'none', viewValue: 'None'},
    {value: 'discipline', viewValue: 'Discipline'},
    {value: 'special_role', viewValue: 'Special Role'},
  ];

  filterValues= [];

  root = environment.root;
  searchTerm = '';
  myUsers = [];
  selected = -1;
  filterSelected = false;
  currentFilter = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private specialRolesService: SpecialRoleService,
        private disciplineService: DisciplinesService,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {

        }
    }

    ngOnInit() {
      this.myUsers = this.users;
    }

    search(value: string): void {
      let results = this.myUsers.filter((u) => {
        return u.name.toLowerCase().includes(value.toLowerCase())
      });
      this.usersEvent.emit(results);
    }

    filterSet(value) {
      if(value == 'none') {
        this.filterSelected = false;
        this.filterValueSet([]);
      } else {
        if(value == 'discipline') {
          this.disciplineService.getAll().subscribe(res => {
            this.filterValues = res;
          });
        } else if(value == 'special_role'){
          this.specialRolesService.getAll().subscribe(res => {
            this.filterValues = res
          });
        }

        this.currentFilter = value;
        this.filterSelected = true;
      }

    }
    filterValueSet(value) {

      let results = this.myUsers.filter((u) => {
        if(value.length == 0) {
          return u[this.currentFilter]!==null;
        } else {
          return value.includes(u[this.currentFilter]);
        }

      });
      this.usersEvent.emit(results);
    }
    sortSet(value) {
      console.log(this.myUsers, value)
      this.myUsers.sort((a,b) => {
        if ( a[value] < b[value] ){
          return -1;
        }
        if ( a[value] > b[value] ){
          return 1;
        }
        return 0;
      })
      this.usersEvent.emit(this.myUsers);
    }
}
