import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from '../../services/mail.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from '../../services/user.service';
import { OnboardingRecordsService } from 'src/app/services/onboarding-records.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-mass-contact',
  templateUrl: './mass-contact.component.html',
  styleUrls: ['./mass-contact.component.scss']
 })
export class MassContactComponent implements OnInit {
  public currentUser: any;

  userToggle: any;

  email: any;
  totalCompleted=0;
  emails = [];

  contactReasons: any[] = [
    {value: 'Missing Task'},
    {value: 'Incorrect Status'},
    {value: 'General Issue'},
    {value: 'Feature Request'},
    {value: 'Other'}
  ];

  root = environment.root;

  contactForm = this.formBuilder.group({
    subject: '',
    message: ''
  });

  constructor(
      private formBuilder: FormBuilder,
      private mail: MailService,
      private UserService: UserService,
      private alertService: AlertService,
      private authenticationService: AuthenticationService,
      private onBoardRecordService: OnboardingRecordsService,
      private http: HttpClient
  ) {
    this.currentUser = this.authenticationService.currentUserValue;

    this.UserService.getAll().subscribe(res => {
      for(let r of res){
        this.emails.push(r.email);
      }

    });

    if(this.root != "") {
      this.userToggle = window.location.href.split('/')[6]; //uat site
    }else{
      this.userToggle = window.location.href.split('/')[4]; // local or domain root
    }

    if(this.userToggle != null) {
      this.userToggle = this.userToggle.split('-')[1];

      if(this.userToggle == "incorrect") {
        this.contactForm.controls['subject'].setValue('Incorrect Status');
      }else {
        this.contactForm.controls['subject'].setValue('Missing Task');
      }
    }
  }

  ngOnInit() {
  }

  getDate() {
    let today = new Date();
    let day = '';
    const yyyy = today.getFullYear();
    let mm = String(today.getMonth() + 1).padStart(2, '0')
    let dd = String(today.getDate() + 1).padStart(2, '0')

    day = yyyy + '-' + mm + '-' + dd;
    return day;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async emailOut(x,to) {
    let msg = x+'- Hi '+to.toString();

        await this.mail.sendContactEmail(to.toString(), 'ECWE MEMO by ' +this.currentUser.email + ': ' + this.contactForm.value.subject, msg).subscribe( async res => {
          //console.log(res);
          if(res){
            this.alertService.success('Message has been sent!', true);
          }else{
            this.alertService.error('Message failed to send!', false);
          }
        });
  }

  onSubmit(): void {
    let x=0;
    let i=0;

    const middleIndex = Math.ceil(this.emails.length / 2);

    let set1 = this.emails.slice(0,middleIndex);
    let set2 = this.emails.slice(-middleIndex);

    const middle2Index = Math.ceil(set1.length / 2);
    let set1a = set1.slice(0,middle2Index);
    let set1b = set1.slice(-middle2Index);

    const middle3Index = Math.ceil(set2.length / 2);
    let set2a = set2.slice(0,middle3Index);
    let set2b = set2.slice(-middle3Index);

    let emailSets = [set1a,set1b,set2a, set2b];

    for(let j=0; j<emailSets.length; j++){
        this.sleep(10000).then(() => { i=0; x++; this.emailOut(x,emailSets[j]) });
    }
    //let msg = this.contactForm.value.message;

    //console.log(this.emails.toString());

    // Process checkout data here
    //this.mail.sendContactEmail('andrew.phillips-g@arup.com', 'ECWE MEMO by ' +this.currentUser.email + ': ' + this.contactForm.value.subject, msg).subscribe( async res => {
      // res.subscribe(r =>{
      //   console.log(r);
      // });


    //   console.log(res);
    //   if(res){
    //     this.alertService.success('Message has been sent!', true);
    //   }else{
    //     this.alertService.error('Message failed to send!', false);
    //   }
    // });
    console.warn('Your order has been submitted', this.contactForm.value);
    this.contactForm.reset();
  }
}
