import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Users } from '../../types/user';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-tool-manager',
  templateUrl: 'tool-manager.component.html',
  styleUrls: ['./tool-manager.component.scss']
 })
export class ToolManagerComponent implements OnInit {
    currentUser: Users;

    root = environment.root;

    editUserId: any;
    activeTool = '';

    constructor(
        private router: Router,
        private user: UserService,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        } else {
            this.currentUser = this.authenticationService.currentUserValue;

            if(this.root != "") {
              this.editUserId = window.location.href.split('/')[7]; //uat site
              this.activeTool = window.location.href.split('/')[6]; //uat site
            }else{
              this.editUserId = window.location.href.split('/')[5]; // local or domain root
              this.activeTool = window.location.href.split('/')[4]; // local or domain root
            }
        }
    }

    ngOnInit() {

    }
}
