import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { AdminUpdateComponent } from '../admin-update/admin-update.component';
import { ContactFormDialogComponent } from '../contact-form-dialog/contact-form-dialog.component';
import { MessageComponent } from '../message/message.component';
import { DialogTemplate } from './templates/dialog.component';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    DialogTemplate,
    ContactFormDialogComponent,
    MessageComponent,
    AdminUpdateComponent
  ],
  imports:[
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    DialogTemplate,
    ContactFormDialogComponent,
    MessageComponent,
    AdminUpdateComponent
  ],
  entryComponents: [
    DialogTemplate,
    ContactFormDialogComponent,
    MessageComponent,
    AdminUpdateComponent
  ]
})
export class DialogModule { }
