import { AfterViewInit, Component, Input, OnInit, ViewChild, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { OnboardingMaterialsService } from '../../services/onboarding-materials.service';
import { OnboardingMaterialTypeService } from '../../services/onboarding-material-type.service';
import { DisciplinesService } from '../../services/disciplines.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Users } from '../../types/user';
import { environment } from 'src/environments/environment';

export interface PeriodicElement {
  name: string;
  type: string;
  status: string;
  expiration_date: string;
  // assignee: string;
  last_updated: string;
  actions: boolean;
  url: string;
}



@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
 })
export class OnboardingComponent implements OnInit {
  public currentUserSubject: BehaviorSubject<Users>;
  public currentUser: Observable<Users>;

  displayedColumns: string[] = ['include', 'name', 'type', 'status', 'expiration_date'];

  private companySubscription: Subscription;


  @Input() mode: string;
  @Input() myMaterials: any;
  @Input() myCategory: any;
  @Input() myCompany: any;
  @Input() srs: any;
  @Input() ato: any;
  @Output() materialsEvent = new EventEmitter<any>();

  root = environment.root;

  user: any;
  materials: any;
  existingMaterials: any;
  types: any;
  disciplineLeads= [];
  selectedMaterials = [];
  statuses = ['To do', 'Not Applicable'];
  //myformArray: any;

  myformArray = new FormArray([
    new FormGroup({
      id: new FormControl(0),
      include: new FormControl(false),
      name: new FormControl(''),
      type: new FormControl(''),
      status: new FormControl(''),
      expiration_date: new FormControl(''),
      assignee: new FormControl(null),
      url: new FormControl('')
    })
  ]);
  dataSource:any;
  editing=0;
  startDate = new Date(2022, 0, 1);
  defaultExpDate = null;

  currCat = null;
  currCompany = null;
  materialList = [];
  internalOnly = [];
  externalOnly = [];

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private UserService: UserService,
        private MaterialsService: OnboardingMaterialsService,
        private MaterialTypeSerivce: OnboardingMaterialTypeService,
        private DisciplinesService: DisciplinesService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
      this.currentUser = this.authenticationService.currentUserValue;

    }

    ngOnInit() {
      this.currCat = this.myCategory;
      this.currCompany = this.myCompany;


      this.DisciplinesService.getAll().subscribe( res => {
        res.map(lead => {
          if(lead != null && lead.lead != null){
            this.disciplineLeads.push(lead);
          }
        });
        this.disciplineLeads.sort((a, b) => a.lead.normalize().localeCompare(b.lead.normalize()));
      });
      this.MaterialTypeSerivce.getAll().subscribe( res => {
        this.types = res;
      });

      if(this.mode == 'Edit' || this.mode == 'Duplicate'){
        this.editing=1;
      }


      if(this.editing == 0) {
        this.MaterialsService.getAll().subscribe(res => {
          this.materials = res;
          console.log('no materials', this.materials);
          this.generateForm();
        });
      }else {
        this.MaterialsService.getAll().subscribe(res => {
          this.materials = res;
          this.editing=1;
          this.existingMaterials = this.myMaterials;
          console.log('materials', this.existingMaterials);
          this.generateForm();
        });
      }

      this.defaultExpDate = this.setDefaultDate();
    }

    ngAfterContentChecked(): void {
      this.cdr.detectChanges();
   }

    setDefaultDate() {
      let today = new Date();
      today.setDate(today.getDate() + 25);
      let day = '';
      const yyyy = today.getFullYear();
      let mm = String(today.getMonth() + 1).padStart(2, '0')
      let dd = String(today.getDate() + 1).padStart(2, '0')

      day = yyyy + '-' + mm + '-' + dd;
      return day;
    }

    autoPopDate(id, i ) {
      this.myformArray.controls[i].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
    }

    generateForm(){
      this.myformArray.removeAt(0);

      if(this.editing==1){
        let res = this.materials.map(obj => this.existingMaterials.find(o => o.onboarding_material === obj.name) || obj);

        res.map(item => {
          let defaultTriggered = false;

          // if(item.contract == this.myCategory && this.myCategory == '2') {
          //   defaultTriggered = true;
          // }

          if(item.email){
            if(this.srs == true && item.onboarding_material.indexOf('Extension - SRS') !== -1){
              defaultTriggered = true;
            }
            if(this.ato == true && item.onboarding_material.indexOf('Extension - ATO') !== -1){
              defaultTriggered = true;
            }
          }else {
            if(this.srs == true && item.name.indexOf('Extension - SRS') !== -1){
              defaultTriggered = true;
            }
            if(this.ato == true && item.name.indexOf('Extension - ATO') !== -1){
              defaultTriggered = true;
            }
          }

            let myArr =  new FormGroup({
              id: new FormControl((item.email ? item.id : 0)),
              include: new FormControl((item.email ? true : false)),
              name: new FormControl((item.email ? item.onboarding_material : item.name)),
              type: new FormControl(item.material_type),
              status: new FormControl((item.email ? item.status : this.statuses[0])),
              expiration_date: new FormControl(item.expiration_date),
              assignee: new FormControl((item.email ? item.assignee : null)),
              url: new FormControl((item.email ? item.url : item.url))
            });
            this.myformArray.push(myArr);
        });
      }else {

        this.materials.map(item => {
          let defaultTriggered = false;
          let setDate = null;

          if(item.contract == 'All' || item.contract == 'Arup') {
            defaultTriggered = true;
            setDate = this.defaultExpDate;
          }

          // if(item.contract == this.myCategory && this.myCategory == '2') {
          //   defaultTriggered = true;
          //   setDate = this.defaultExpDate;
          // }

          if(this.srs == true && item.name.indexOf('Extension - SRS') !== -1){
            defaultTriggered = true;
            setDate = this.defaultExpDate;
          }
          if(this.ato == true && item.name.indexOf('Extension - ATO') !== -1){
            defaultTriggered = true;
            setDate = this.defaultExpDate;
          }


          let myArr =  new FormGroup({
            id: new FormControl((item.email ? item.id : 0)),
            include: new FormControl((defaultTriggered)),
            name: new FormControl((item.email ? item.onboarding_material : item.name)),
            type: new FormControl(item.material_type),
            status: new FormControl((item.email ? item.status : this.statuses[0])),
            expiration_date: new FormControl(setDate),
            assignee: new FormControl((item.email ? item.assignee : null)),
            url: new FormControl((item.email ? item.url : item.url))
          });
          this.myformArray.push(myArr);
        });
      }

      this.materialList = this.materials;
      this.getInternalOnly();
      this.getExternalOnly();

      //emit values
      this.myformArray.valueChanges.subscribe(x => {
        this.materialsEvent.emit(this.myformArray);
      })

      this.dataSource = this.myformArray.controls;
    }


    ngOnChanges(changes: SimpleChanges): void {
      let coi = this.getConflictIndex();
      let srsIndex = this.getExtraItem('Extension - SRS');
      let atoIndex = this.getExtraItem('Extension - ATO');

      //console.log(this.srs, this.ato, this.currCompany, this.myCompany);


      // handle change in category
      // if(this.currCat != this.myCategory){
      //   if(this.myCategory == 2) { //add COI
      //     this.myformArray.controls[coi].patchValue({ include: true });
      //     this.myformArray.controls[coi].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
      //   }else { //remove COI
      //     this.myformArray.controls[coi].patchValue({ include: false });
      //     this.myformArray.controls[coi].patchValue({ expiration_date: null });
      //   }
      // }

      // handle change in company
      if(this.currCompany != this.myCompany){
        if(this.myCompany == 'Arup') { //add arup items remove external
          this.addDynamicItems(this.internalOnly);
          if(this.srs){
            this.myformArray.controls[srsIndex].patchValue({ include: true });
            this.myformArray.controls[srsIndex].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
          }
          if(this.ato){
            this.myformArray.controls[atoIndex].patchValue({ include: true });
            this.myformArray.controls[atoIndex].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
          }
          // if(this.myCategory == 2) { //add COI
          //   this.myformArray.controls[coi].patchValue({ include: true });
          //   this.myformArray.controls[coi].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
          // }
        }else { //remove arup add external
          this.addDynamicItems(this.externalOnly);
          this.myformArray.controls[srsIndex].patchValue({ include: false });
          this.myformArray.controls[srsIndex].patchValue({ expiration_date: null });
          this.myformArray.controls[atoIndex].patchValue({ include: false });
          this.myformArray.controls[atoIndex].patchValue({ expiration_date: null });
          this.myformArray.controls[coi].patchValue({ include: false });
          this.myformArray.controls[coi].patchValue({ expiration_date: null });
        }
      }

      if(this.srs && this.myCompany == 'Arup'){
        this.myformArray.controls[srsIndex].patchValue({ include: true });
        this.myformArray.controls[srsIndex].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
      }else{
        this.myformArray.controls[srsIndex].patchValue({ include: false });
        this.myformArray.controls[srsIndex].patchValue({ expiration_date: null });
      }

      if(this.ato && this.myCompany == 'Arup'){
        this.myformArray.controls[atoIndex].patchValue({ include: true });
        this.myformArray.controls[atoIndex].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
      }else{
        this.myformArray.controls[atoIndex].patchValue({ include: false });
        this.myformArray.controls[atoIndex].patchValue({ expiration_date: null });
      }
    }

    //Extension - SRS
    //Extension - ATO
    getExtraItem(val) {
      let i = 0;
      this.materialList.map((res,j) => {
        if(res.name.indexOf(val) !== -1){
          i=j;
        }
      });
      return i;
    }


    addDynamicItems(mode) {
      this.materialList.map((res,i) => {
        if (mode.filter(e => e.name === res.name).length > 0) {
          /* vendors contains the element we're looking for */
          this.myformArray.controls[i].patchValue({ include: true });
          this.myformArray.controls[i].patchValue({ expiration_date: this.defaultExpDate + 'T00:00:00.000Z' });
        }else {
          if(res.name.indexOf('Conflict') !== -1){
            //dont add conflict
          }else if(res.name.indexOf('SSRA') !== -1){
            //dont add extras
          }else {
            this.myformArray.controls[i].patchValue({ include: false });
            this.myformArray.controls[i].patchValue({ expiration_date: null });
          }
        }
      });
    }

    getConflictIndex() {
      let i = 0;
      this.materialList.map((res,j) => {
        if(res.name.indexOf('Conflict') !== -1){
          i=j;
        }
      });
      return i;
    }

    getInternalOnly() {
      this.materialList.map((res,j) => {
        if(res.name.indexOf('Conflict') !== -1){
          //dont add conflict
        }else if(res.name.indexOf('External') !== -1){
          //dont add external
        }else if(res.name.indexOf('SSRA') !== -1){
          //dont add extras
        }else {
          this.internalOnly.push(res);
        }
      });
    }

    getExternalOnly() {
      this.materialList.map((res,j) => {
        if(res.material_type == 'SharePoint' && res.contract == 'All'){
          this.externalOnly.push(res);
        }else if(res.material_type == 'PDF'){
          this.externalOnly.push(res);
        }
      });
    }

}
