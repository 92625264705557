import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from '../../services/mail.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from '../../services/user.service';
import { OnboardingRecordsService } from 'src/app/services/onboarding-records.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-contactForm',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
 })
export class ContactFormComponent implements OnInit {
  public currentUser: any;

  userToggle: any;

  myMaterials= [];
  moodleMaterials= [];
  moodleList= [];
  email: any;
  totalCompleted=0;
  moodleToken=null;

  contactReasons: any[] = [
    {value: 'Missing Task'},
    {value: 'Incorrect Status'},
    {value: 'General Issue'},
    {value: 'Feature Request'},
    {value: 'Other'}
  ];

  root = environment.root;

  contactForm = this.formBuilder.group({
    subject: '',
    message: ''
  });

  constructor(
      private formBuilder: FormBuilder,
      private mail: MailService,
      private UserService: UserService,
      private alertService: AlertService,
      private authenticationService: AuthenticationService,
      private onBoardRecordService: OnboardingRecordsService,
      private http: HttpClient
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.UserService.getAZ({}).subscribe(res => {
      this.moodleToken = res;
    });

    if(this.root != "") {
      this.userToggle = window.location.href.split('/')[6]; //uat site
    }else{
      this.userToggle = window.location.href.split('/')[4]; // local or domain root
    }

    if(this.userToggle != null) {
      this.userToggle = this.userToggle.split('-')[1];

      if(this.userToggle == "incorrect") {
        this.contactForm.controls['subject'].setValue('Incorrect Status');
      }else {
        this.contactForm.controls['subject'].setValue('Missing Task');
      }
    }
  }

  ngOnInit() {
    this.getMaterials();
  }


  getMaterials(){
    this.onBoardRecordService.findByEmail(this.currentUser.email).subscribe(res => {
      this.myMaterials = res;
      this.myMaterials.map(mat => { // capture moodle for examination
        if(mat.material_type == 'Moodle'){
          this.moodleMaterials.push(mat);
        }
        mat.expiration_date = mat.expiration_date.split('T')[0];
        if(mat.last_updated != null)
          mat.last_updated = mat.last_updated.split('T')[0];
      })

      setTimeout(() => {
        this.moodleChecker();
      },3000);

    });
  }

  getDate() {
    let today = new Date();
    let day = '';
    const yyyy = today.getFullYear();
    let mm = String(today.getMonth() + 1).padStart(2, '0')
    let dd = String(today.getDate() + 1).padStart(2, '0')

    day = yyyy + '-' + mm + '-' + dd;
    return day;
  }

  moodleChecker() {
    //moodle check
    //console.log(this.user);
    //console.log(this.moodleMaterials);

    this.moodleCall().subscribe(res => {
      let list = res.value;

      list.map(item => {
        if (this.moodleMaterials.filter(e => e.url.indexOf(item.CourseId) !== -1).length > 0) {
          /* vendors contains the element we're looking for */

          let moodle = this.moodleMaterials.filter(e => e.url.indexOf(item.CourseId) !== -1)

          this.moodleList.push(moodle);
        }
      })
    });

  }

  moodleCall():Observable <any> {
    let user = this.currentUser.email;

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Ocp-Apim-Subscription-Key': '5bcff4a8e64f4041a023ea29352106fc',
      'Authorization': `Bearer ${this.moodleToken.access_token}`
      //'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJodHRwczovL2FydXAub25taWNyb3NvZnQuY29tL0FJUyIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzRhZTQ4YjQxLTAxMzctNDU5OS04NjYxLWZjNjQxZmU3N2JlYS8iLCJpYXQiOjE2NjY0MDY4NTIsIm5iZiI6MTY2NjQwNjg1MiwiZXhwIjoxNjY2NDExOTg4LCJhY3IiOiIxIiwiYWlvIjoiQVRRQXkvOFRBQUFBbXh1c0U1RkhzUGhhSzlPUXZSZGNkN1ZYVGV6RkFkRTA2L3YrQUtLUTNQdkpHUHVNeHBmYlk4VHN6RStKR0FtNSIsImFtciI6WyJwd2QiXSwiYXBwaWQiOiIyOTNmNzExYy02ZDNjLTQxM2ItOTU5ZC1kMzFkN2IyMDU4NzgiLCJhcHBpZGFjciI6IjEiLCJmYW1pbHlfbmFtZSI6IlNWQy1BQUQiLCJnaXZlbl9uYW1lIjoiU1ZDLUFEUy1NYWpvcl9Qcm9qZWN0c190b29sIiwiZ3JvdXBzIjpbIjJmYzNkY2Y4LTY2Y2MtNDgwOS05NWU0LTg2Yjc5ZmRhYjgxNiIsIjNiYjYwZTgxLWM4YWQtNGIyNi1iYTU1LWE0NGIxZjYyNWU4OSIsImU3ODI1ZWE5LTI4YWQtNDU5Ni05NTE4LThiMmQyN2RhMGUwNiJdLCJpcGFkZHIiOiI0NS41OC4yNDUuMTg0IiwibmFtZSI6IlNWQy1BRFMtTWFqb3JfUHJvamVjdHNfdG9vbCIsIm9pZCI6IjYyNmNhODg4LWFjMmEtNGJiOC1iMmFhLTQ1NTkzYWU3NjU2YSIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS01MTU5Njc4OTktNDkyODk0MjIzLTcyNTM0NTU0My0xMjMzMzI3IiwicmgiOiIwLkFRVUFRWXZrU2pjQm1VV0dZZnhrSC1kNzZpNFNRbFpVNms1RWthT3M4bzZFN1VRRkFETS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJxZ3VGNFNkWkFLcWw1T0N4Q2R3c0J6dmRJQkZKT01SSWdKWlRneDZ1M05rIiwidGlkIjoiNGFlNDhiNDEtMDEzNy00NTk5LTg2NjEtZmM2NDFmZTc3YmVhIiwidW5pcXVlX25hbWUiOiJTVkMtQURTLU1ham9yX1Byb2plY3RzX3Rvb2xAYXJ1cC5vbm1pY3Jvc29mdC5jb20iLCJ1cG4iOiJTVkMtQURTLU1ham9yX1Byb2plY3RzX3Rvb2xAYXJ1cC5vbm1pY3Jvc29mdC5jb20iLCJ1dGkiOiJaMnpFZF9fcmZVV2xHYXZfMEpJQkFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXX0.Tja7YstsU1wyMTX_hIh9OPPf4p8Jd2o_95uIBFtD8K72El_DbZOhfozq5UJXrVvs4a5e72NR-Ms5kY2zp59_ymM-xGmV4EOZRIzVUziiAh1b-2iywL0U_BuBkGdXQpwr53cVp2gkElKsOBZZNTuiDHm_i5Zm4l5KlHrrfvp7J9qn_MpytAGR1AH3e62A1o_N1YuTLFaxbPFqcuP2_Bsr6vfv-PvAiR5Mai0eAqMk2RdWoSfnZjx0Z08LbwiqmnKg0q9nwkN8u2Cs6rByu1LKpaikmmceHBXO2n0kPm84ghccFgK5PW3xlVM3LVppvv2emjoZR67sSk3bIQ_Tbh6dig'
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    //console.log(user, requestOptions);
    //console.log(this.moodleToken.access_token)
    let test = this.http.get(`https://adsprapiman.azure-api.net/ads/MoodleCourseCompletions?$filter=Upn eq '${user}'`, requestOptions);
    return test;
    //return;
  }

  onSubmit(): void {
    let msg = this.contactForm.value.message + `\n\n ::::USER:::: \n\n`;

    for (const [key, value] of Object.entries(this.currentUser)) {
      msg += `${key}: ${value}` + '  -  \n';
    }

    msg += `\n\n ::::MOODLE:::: \n\n`;

    console.log(this.moodleList);

    for(let mood of this.moodleList){
      for (const [key, value] of Object.entries(mood[0])) {
        msg += `${key}: ${value}` + '\n  -  \n';
      }
    }

    // Process checkout data here
    this.mail.sendContactEmail('andrew.phillips-g@arup.com', this.currentUser.email + ' reported: ' + this.contactForm.value.subject, msg).subscribe( async res => {
      // res.subscribe(r =>{
      //   console.log(r);
      // });


      console.log(res);
      if(res){
        this.alertService.success('Message has been sent!', true);
      }else{
        this.alertService.error('Message failed to send!', false);
      }
    });
    console.warn('Your order has been submitted', this.contactForm.value);
    this.contactForm.reset();
  }
}
