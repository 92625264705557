import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from 'src/app/services/mail.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { CompaniesService } from 'src/app/services/companies.service';
import { SubCompaniesService } from 'src/app/services/sub-companies.service';
import { PersonnelCategoryService } from 'src/app/services/personnel_category.service';
import { DisciplinesService } from 'src/app/services/disciplines.service';
import { DisciplineCategoryService } from 'src/app/services/discipline-category.service';
import { SpecialRoleService } from 'src/app/services/special-role.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { StatusService } from 'src/app/services/status.service';
import { StatusRecordsService } from 'src/app/services/status-records.service'
import { AlertService } from 'src/app/services/alert.service';

import moment from 'moment';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
 })
export class MessageComponent implements OnInit {
  public currentUser: Observable<Users>;
  @Input() showUser: number;
  @Input() loadedUser: any;
  @Input() user: any;

  approvalForm = this.formBuilder.group({
    message: ''
  });

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private UserService: UserService,
        private StatusRecords: StatusRecordsService,
        private router: Router,
        private mail: MailService,
        private userService: UserService,
        private alertService: AlertService,
        private statusService: StatusService,
        private StatusRecordsService: StatusRecordsService,
        private DisciplinesService: DisciplinesService,
        private authenticationService: AuthenticationService
    ) {
      this.currentUser = this.authenticationService.currentUserValue;

    }

    async ngOnInit() {
      // if (this.showUser == null) {
      //   this.user = this.currentUser;
      // }else {
      //   this.user = this.loadedUser;
      // }

      console.log('user:', this.user);

    }

    getDate() {
      let today = new Date();
      let day = '';
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = 0 + dd;
      if (mm < 10) mm = 0 + mm;

      return day = yyyy + '-' + mm + '-' + dd;
    }
    dateAdjust(date) {
      return moment(date).format("YYYY-MM-DD");
    }

    onSubmit(): void {

      const subject = "ECWE push notification.";
      const message = this.approvalForm.value.message;

      this.mail.sendContactEmail(this.user.email, subject, message).subscribe(res => {
        this.alertService.success('User has been added and notified!', true);
        console.log(res)
      });

      console.warn('Your order has been submitted', this.approvalForm.value);
      this.approvalForm.reset();
    }
}
