import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'LeftPadFilter',
  pure: false
})
export class LeftPadFilter implements PipeTransform {
  transform(item: string): string {
      return (String('0').repeat(3) + item).substr((3 * -1), 3);
  }
}
