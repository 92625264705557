import { Component, ComponentFactoryResolver, ElementRef, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Users } from '../../types/user';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert.service';
import { MailService } from 'src/app/services/mail.service';
import { OnboardingRecordsService } from 'src/app/services/onboarding-records.service';


@Component({
  selector: 'app-task',
  templateUrl: 'task.component.html',
  styleUrls: ['./task.component.scss']
 })
export class TaskComponent implements OnInit {

    @ViewChild('iframe', {
      static: true
    }) iframe: ElementRef;
    @ViewChild('completed', {
      static: false
    }) completed: ElementRef<HTMLElement>;

    currentUser: Users;
    materialItem: any;
    materialId = 0;
    loaded = false;
    moodle = false;
    sharepoint = false;
    learningItem = '';

    root = environment.root;

    completedBTN: any;
    ready = false;

    constructor(
        private router: Router,
        private sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private mail: MailService,
        private onBoardRecordService: OnboardingRecordsService,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        } else {
            this.currentUser = this.authenticationService.currentUserValue;
        }
    }

    ngOnInit() {
      if(this.root != "") {
        this.materialItem = window.location.href.split('/')[6]; //uat site
        this.materialId = parseInt(window.location.href.split('/')[7]); //uat site
        this.moodle = (window.location.href.split('/')[8] == 'Moodle' ? true : false ); //type
        this.sharepoint = (window.location.href.split('/')[8] == 'SharePoint' ? true : false ); //type
      }else{
        this.materialItem = window.location.href.split('/')[4]; // local or domain root
        this.materialId = parseInt(window.location.href.split('/')[5]); // local or domain root
        this.moodle = (window.location.href.split('/')[6] == 'Moodle' ? true : false ); //type
        this.sharepoint = (window.location.href.split('/')[6] == 'SharePoint' ? true : false ); //type
      }

      // if(this.moodle) {
      //   this.getMaterialItem().subscribe(res => {
      //     this.iframe.nativeElement.src = res;
      //     this.iframe.nativeElement.reload();
      //   });
      // }

      this.materialItem = this.materialItem.replace(/%2F/gi, "/");
      this.materialItem = this.materialItem.replace(/%25/gi, "%");
      this.materialItem = this.materialItem.replace(/%3D/gi, "=");
      this.materialItem = this.materialItem.replace(/%3F/gi, "?");
      this.completedBTN = document.getElementById("completed");

      this.learningItem = this.getMaterialItem();


      setTimeout(()=> {
        this.readyToComplete();
      }, 30000 );

    }

    showMood(){
        var elmnt = this.iframe.nativeElement.contentWindow.document.getElementsByTagName("body")[0].getAttribute('id');

        console.log(elmnt)
        if(elmnt == 'page-auth-saml-login') {
          this.iframe.nativeElement.remove();
        }else{
        }
    }

    readyToComplete() {
        this.completedBTN.classList.remove('not-ready');
        this.ready = true;
        console.log('ready');
    }

    getDate() {
      let today = new Date();
      let day = '';
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = 0 + dd;
      if (mm < 10) mm = 0 + mm;

      return day = yyyy + '-' + mm + '-' + dd;
    }

    markAsComplete() {
      if(!this.ready) {
        this.alertService.error('You cannot mark this as completed just yet!!', true);
      }else {

        let id = this.materialId;
        let record = {
          status: 'Completed',
          date_completed: this.getDate()
        }
        this.onBoardRecordService.update(id,record).subscribe(async res => {
          console.log('made this record:: ', res);

          document.getElementById('goBack').click();

          const subject = "You\'ve completed an item for on-boarding!";
          const message = "Hello "+this.currentUser.name+", you have just completed another item for your on-boarding.";

          // this.mail.sendContactEmail(this.currentUser.email, subject, message).subscribe(res => {
          //   this.alertService.success('Requirement item has been completed!', true);
          //   console.log(res)
          // });
        });

      }
    }

    getMaterialItem() {
      //use this for dev
      //return this.sanitizer.bypassSecurityTrustResourceUrl(''+this.root+'/s3/' + this.materialItem);

      let res = null;
      if (this.materialItem.substring(0, 5) == 'local') {
        res = this.sanitizer.bypassSecurityTrustResourceUrl(''+this.root+'/assets/materials/' + this.materialItem);
      }else {
        res = this.sanitizer.bypassSecurityTrustResourceUrl(this.materialItem);
      }

      // setTimeout(() => {
      //   this.showMood();
      // },1000)
      return res;
    }

    ngAfterViewInit() {

    }
}
