import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from 'src/app/services/mail.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Users } from '../../types/user';
import { StatusRecordsService } from 'src/app/services/status-records.service';
import { UserService } from 'src/app/services/user.service';
import moment from 'moment';


@Component({
  selector: 'app-csleUsers',
  templateUrl: './csl-users.component.html',
  styleUrls: ['./csl-users.component.scss']
 })
export class CSLUsersComponent implements OnInit {
  public currentUser: Observable<any>;
  public usersList: Observable<any>;

  loaded = false;
  activeProj = 'srs';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private UserService: UserService,
        private statusRecords: StatusRecordsService,
        private router: Router,
        private mail: MailService,
        private authenticationService: AuthenticationService
    ) {
      this.currentUser = this.authenticationService.currentUserValue;

      this.usersList = this.UserService.findByStatus('queued_csl').pipe(
        tap( res => {this.loaded = true }),
        map( res => {
          res.map( r  => {
            this.UserService.get(r.id).subscribe(res2 => {
              let user = res2;
              r.atc1_package = user.atc1_package;
              r.atc2_package = user.atc2_package;
              r.company = user.company;
              r.discipline = user.discipline;
              r.discipline_category = user.discipline_category;
              r.eg_package = user.eg_package;
              r.email = user.email;
              r.mobile_phone = user.mobile_phone;
              r.notes = user.notes;
              r.office_phone = user.office_phone;
              r.permissions = user.permissions;
              r.personnel_category = user.personnel_category;
              r.special_role = user.special_role;
              r.srs_package = user.srs_package;
              r.as_package = user.as_package;
              r.status = user.status;
              r.sub_company = user.sub_company;
            })
          });
          return res;
        }),
        map( res => {
          res.map(r => {
            r.expanded = false;
          });
          return res
        })
      );

    }

    ngOnInit() {

    }

    toggleProject(project) {
      this.activeProj = project;
    }

}
