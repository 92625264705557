import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './containers/home/home.component';
import { AuthGuard } from './helpers/auth.guard';
import { MyTasksComponent } from './containers/my-tasks/my-tasks.component';
import { ExistingUsersComponent } from './containers/existing-users/existing-users.component';
import { ToolManagerComponent } from './containers/tool-manager/tool-manager.component';
import { SupportComponent } from './containers/support/support.component';
import { TaskComponent } from './containers/task/task.component';
import { NewUsersComponent } from './containers/new-users/new-users.component';
import { ApproveUsersComponent } from './containers/approve-users/approve-users.component';
import { AdminComponent } from './containers/admin/admin.component';
import { AdminNewEditComponent } from './components/admin-new-edit/admin-new-edit.component';
import { MassContactComponent } from './components/mass-contact/mass-contact.component';
import { CSLUsersComponent } from './containers/csl-users/csl-users.component';


const routes: Routes = [

  { path: '', component: HomeComponent },
  { path: 'logout', component: HomeComponent },
  { path: 'my-tasks', component: MyTasksComponent, canActivate: [AuthGuard] },
  { path: 'task/:id/:matId/:type', component: TaskComponent, canActivate: [AuthGuard] },
  { path: 'existing-users', component: ExistingUsersComponent, canActivate: [AuthGuard] },
  { path: 'users', component: NewUsersComponent, canActivate: [AuthGuard] },
  { path: 'csl-users', component: CSLUsersComponent, canActivate: [AuthGuard] },
  { path: 'approve-users', component: ApproveUsersComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'admin/new-edit/:id', component: AdminNewEditComponent, canActivate: [AuthGuard] },
  { path: 'admin/mass-contact', component: MassContactComponent, canActivate: [AuthGuard] },
  { path: 'tool-manager/new-user', component: ToolManagerComponent, canActivate: [AuthGuard] },
  { path: 'tool-manager/edit/:id', component: ToolManagerComponent, canActivate: [AuthGuard] },
  { path: 'tool-manager/duplicate/:id', component: ToolManagerComponent, canActivate: [AuthGuard] },
  { path: 'tool-manager/new-csl-request', component: ToolManagerComponent, canActivate: [AuthGuard] },
  { path: 'tool-manager/edit-csl-request/:id', component: ToolManagerComponent, canActivate: [AuthGuard] },
  { path: 'support', component: SupportComponent, canActivate: [AuthGuard] },
  { path: 'support/report-missing-task', component: SupportComponent, canActivate: [AuthGuard] },
  { path: 'support/report-incorrect-status', component: SupportComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

