import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { OnboardingRecords } from '../types/onboarding-records';
import { environment } from '../../environments/environment';

const baseUrl = environment.apiUrl + '/onboarding-records';

@Injectable({ providedIn: 'root' })
export class OnboardingRecordsService {
  constructor(private http: HttpClient) { }
  getAll(): Observable<OnboardingRecords[]> {
    return this.http.get<OnboardingRecords[]>(baseUrl);
  }
  get(id: any): Observable<any> {
    return this.http.get(`${baseUrl}/${id}`);
  }
  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }
  update(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  }
  delete(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`);
  }
  deleteAll(): Observable<any> {
    return this.http.delete(baseUrl);
  }
  findByEmail(email: any): Observable<OnboardingRecords[]> {
    return this.http.get<OnboardingRecords[]>(`${baseUrl}?email=${email}`);
  }
}
