import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUploadService } from 'src/app/services/file-upload.service';

import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-upload-single',
  templateUrl: 'upload-single.component.html',
  styleUrls: ['./upload-single.component.scss']
})

export class UploadFileComponent implements OnInit {
  currentUser: any;
  currentGroup: any;
  @Input() service: string;
  @Input() file: string;
  @Input() group: string;
  @Input() field: string;
  @Output() fileNew = new EventEmitter<any>();

  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  fileInfos?: Observable<any>;

  videoTypes = ['mov','mp4','avi','mpeg'];
  audioTypes = ['mp3','wav'];
  documentTypes = ['pdf','word','xlsx','csv','xls'];
  imagesTypes = ['jpg','jpeg','JPG','png','gif','tiff','svg'];

  constructor(
    private authenticationService: AuthenticationService,
    private uploadService: FileUploadService,
    private userService: UserService,
    private alertService: AlertService) {
      this.currentUser = this.authenticationService.currentUserValue;
      //this.currentGroup = this.authenticationService.currentUserValue;
    }

  ngOnInit(): void {
    this.currentGroup = this.group;

    //let type = this.file.split(".").pop();

    //this.fileInfos = this.uploadService.getFile(0, this.file, this.currentGroup.name.replace(/\s+/g, '-').toLowerCase(), type);

  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  uploadFiles(): void {
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  //local testing when credential S3 issue occurs
  upload(idx: number, file: File): void {
    let obj = {
      field: this.field,
      val:file.name
    }
    this.fileNew.emit(obj);
  }

  // upload(idx: number, file: File): void {
  //   this.progressInfos[idx] = { value: 0, fileName: file.name };


  //   if (file) {
  //     this.uploadService.upload(file).subscribe({
  //       next: (event: any) => {
  //         if (event.type === HttpEventType.UploadProgress) {
  //           this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
  //         } else if (event instanceof HttpResponse) {
  //           const msg = 'Uploaded the file successfully: ' + file.name;
  //           this.message.push(msg);
  //           //this.fileInfos = this.uploadService.getFiles();
  //           this.fileInfos = this.uploadService.getFile(0,file.name);

  //           let obj = {
  //             field: this.field,
  //             val:file.name
  //           }
  //           this.fileNew.emit(obj);
  //         }
  //       },
  //       error: (err: any) => {
  //         this.progressInfos[idx].value = 0;
  //         const msg = 'Could not upload the file: ' + file.name;
  //         this.message.push(msg);
  //       }});
  //   }
  // }

}
