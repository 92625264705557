import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from 'src/app/services/mail.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { CompaniesService } from 'src/app/services/companies.service';
import { SubCompaniesService } from 'src/app/services/sub-companies.service';
import { PersonnelCategoryService } from 'src/app/services/personnel_category.service';
import { DisciplinesService } from 'src/app/services/disciplines.service';
import { DisciplineCategoryService } from 'src/app/services/discipline-category.service';
import { SpecialRoleService } from 'src/app/services/special-role.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { StatusService } from 'src/app/services/status.service';
import { StatusRecordsService } from 'src/app/services/status-records.service'
import { AlertService } from 'src/app/services/alert.service';
import { tap, map } from 'rxjs/operators';


import moment from 'moment';


@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
 })
export class ApprovalComponent implements OnInit {
  public currentUser: Observable<any>;
  @Input() showUser: number;
  @Input() loadedUser: any;
  @Input() project: any;
  @Input() recId: any;

  delUser = false;
  user: any;
  disciplineLeads= [];
  statuses: any = [];
  packages: any = [];
  userPackages: any = [];
  submitted = false;


  //refactor to make dynamic
  projectPackages= [
    {value:'srs_package', name:'SRS'},
    {value:'atc1_package', name:'ATC1'},
    {value:'atc2_package', name:'ATC2'},
    {value:'eg_package', name:'EG'},
    {value:'as_package', name:'AS'}
  ];

  approvalForm = this.formBuilder.group({
    user: '',
    name: '',
    package: '',
    status: '',
    date: '',
    message: '',
    lead: '',
    srs_package: false,
    atc1_package: false,
    atc2_package: false,
    eg_package: false,
    as_package: false,
    all: false,
    CSL_first: 0
  });

  csls = [];

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private UserService: UserService,
        private StatusRecords: StatusRecordsService,
        private router: Router,
        private mail: MailService,
        private userService: UserService,
        private alertService: AlertService,
        private statusService: StatusService,
        private StatusRecordsService: StatusRecordsService,
        private DisciplinesService: DisciplinesService,
        private authenticationService: AuthenticationService
    ) {
      this.currentUser = this.authenticationService.currentUserValue;

      this.statusService.getAll().subscribe( res => {
        this.statuses = res;
      });
      this.DisciplinesService.getAll().subscribe( res => {
        res.map(lead => {
          if(lead != null && lead.lead != null){
            this.disciplineLeads.push(lead);
          }
        });
        this.disciplineLeads.sort((a, b) => a.lead.normalize().localeCompare(b.lead.normalize()));
      });

    }

    async ngOnInit() {
      let cslCount = 73
      for(let i=1; i<cslCount; i++){
        this.csls.push(i);
      }

      if (this.showUser == null) {
        this.user = this.currentUser;
      }else {
        this.user = this.loadedUser;
      }

      console.log('user:', this.user);

      //refactor this to make it dynamic
      if(this.user.srs_package)
        this.userPackages.push({name: 'SRS', value: 'srs_package'});

      if(this.user.act1_package)
        this.userPackages.push({name: 'ATC1', value: 'act1_package'});

      if(this.user.act2_package)
        this.userPackages.push({name: 'ATC2', value: 'act2_package'});

      if(this.user.eg_package)
        this.userPackages.push({name: 'EG', value: 'eg_package'});

      if(this.user.as_package)
        this.userPackages.push({name: 'AS', value: 'as_package'});

    }

    matchPackage(item){
      return this.userPackages.some(e => e.name === item.name);
    }

    getDate() {
      let today = new Date();
      let day = '';
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = 0 + dd;
      if (mm < 10) mm = 0 + mm;

      return day = yyyy + '-' + mm + '-' + dd;
    }
    dateAdjust(date) {
      return moment(date).format("YYYY-MM-DD");
    }

    togglePackages(item, enabled){
      if(enabled){
        if (!this.packages.includes(item)) {
          this.packages.push(item);
        } else {
          this.packages.splice(this.packages.indexOf(item), 1);
        }
      }
    }

    getUserForAll() {
      let list = this.StatusRecordsService.findByIDStatus('Pending Approval',this.user.id).pipe(
        map( res => {
          return res
        })
      );

      return list;
    }

    submit(subtype): void {
      this.approvalForm.value.all = subtype;

      let mainStatus = this.approvalForm.value.status;
      if(mainStatus=='Approved'){
        mainStatus='Approved';
      }else if(mainStatus=='Not Approved'){
        mainStatus='Not Approved';
      }else if(mainStatus=='Demobilized'){
        mainStatus='Demobilized';
      }

      if(this.approvalForm.value.all == true){ //apply to all
        let mainMsg = this.approvalForm.value.message;
        this.getUserForAll().subscribe(res => {

          for(let i =0; i< res.length; i++) {
            const data = {
              'package': res[i].package,
              'status': mainStatus,
              'date': this.getDate(),
              'message': mainMsg,
              'csl_first': this.approvalForm.value.CSL_first
              //'lead': this.approvalForm.value.lead
            }

            this.createNewUserStatus(res[i].rec_id, data);
          }
        });

      }else{ //single
        const data = {
          'package': this.project,
          'status': mainStatus,
          'date': this.getDate(),
          'message': this.approvalForm.value.message,
          'csl_first': this.approvalForm.value.CSL_first
          //'lead': this.approvalForm.value.lead
        }

        this.createNewUserStatus(this.user.rec_id, data);
      }
    }

    toggleDelete() {
      this.delUser = true;
    }


    createNewUserStatus(id,data) {
      console.log(this.user, this.recId);
      console.log(id, data)
      this.StatusRecordsService.update(id, data).subscribe(async res => {
        let myStatus = {}
        if (this.project == 'SRS') {
          myStatus = {
            status: data.status,
            srs_package: 1
          }
        }
        if (this.project == 'ATC1') {
          myStatus = {
            status: data.status,
            atc1_package: 1
          }
        }
        if (this.project == 'ATC2') {
          myStatus = {
            status: data.status,
            atc2_package: 1
          }
        }
        if (this.project == 'EG') {
          myStatus = {
            status: data.status,
            eg_package: 1
          }
        }
        if (this.project == 'AS') {
          myStatus = {
            status: data.status,
            as_package: 1
          }
        }
         this.userService.update(this.user.id, myStatus).subscribe(async res => {});

        const subject = "Action Required: You have been approved on Contract "+data.package+" of the ECWE Project";
        const message = `Dear ${this.user.name.split(',')[1]}, <br/><br/>
        You may have items to complete in the <a href="https://ecwe.majorprojectstoolkit.arup.com/" target="_new">ECWE Onboarding system</a>. <br/><br/><br/>
        Please click the link to the system above to check for and complete any new Tasks in the system related to this project contract. <br/><br/>
        The Onboarding system was originally rolled out across the ECWE project team in May 2023. The intent of this tool is to capture a comprehensive record of completed critical training tasks such as mandatory health and safety training and project procedures. This record will support a critical function to demonstrate our team's compliance with fulfilling mandatory training and safety requirements in our Quality Audit sessions. As such, timely completion of outstanding items in the Onboarding System is mandatory for all team members across the ECWE project team. <br/><br/>
        For more information / to view the training session on how to use the Onboarding tool, please refer to the May 2023 <a href="https://ecwe.majorprojectstoolkit.arup.com/assets/Project-wide-Roll-Out.mp4" target="_new">ECWE Onboarding Tool Roll Out</a> session recording. <br/><br/>
        If you have any questions, concerns or are having issues accessing the tool / viewing Tasks, please contact the ECWE Staff Mobilization team at: <a href="mailto:ecwestaffmobilization@arup.com">ecwestaffmobilization@arup.com</a>. <br/><br/>
        Thank you in advance for your quick action to complete the above. <br/><br/>
        Regards, <br/>
        ECWE Project Leadership
        `;


        // PUT THIS BACK IN FOR MAILING
        this.mail.sendContactEmail(this.user.email, subject, message).subscribe(res => {
          this.alertService.success('User has been added and notified!', true);
          console.log(res)
        });

        this.submitted = true;
      });

      console.warn('Your order has been submitted', this.approvalForm.value);
      this.approvalForm.reset();
    }
}
