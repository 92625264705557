import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { AuthenticationService } from './services/authentication.service';
// import { AuthService } from './services/authentication.service';
import * as myGlobals from 'globals';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';

import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators'
// import { Subject } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: any;
  currentU: any;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private UserService: UserService,
    private authenticationService: AuthenticationService,
    // private authService: AuthService,
    private broadcastService: MsalBroadcastService
  ) {
    // Grab Azure Token
    this.authenticationService.login();



  }

  async ngOnInit() {
    // //redirect to home if already logged in
    // console.log('currentUserValue', this.authenticationService.currentUserValue);
    // this.currentUser = this.authenticationService.currentUserValue;
    // if (this.authenticationService.currentUserValue) {
    //   await this.UserService.findByEmail(this.currentUser).subscribe(res => {
    //     this.currentUser = res[0];
    //     this.authenticationService.setCurrUser(this.currentUser);
    //   });
    //   this.currentUser = this.authenticationService.currentUserValue;
    // }

    await this.authenticationService.currentUser.subscribe(async x => {
      //console.log(x)
      //this.currentUser = x;
      if (x == null) {
        this.currentUser = this.authenticationService.seeProfile();
          await this.UserService.findByEmail(this.currentUser).subscribe(res => {
            this.currentUser = res[0];
            //console.log(this.currentUser);
            this.authenticationService.setCurrUser(this.currentUser);
          });
      } else {
        await this.UserService.findByEmail(this.currentUser).subscribe(res => {
          this.currentUser = res[0];
          //console.log(this.currentUser);
          this.authenticationService.setCurrUser(this.currentUser);
        });
      }
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
