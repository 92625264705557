export class Users {
  id: number;
  name: string;
  email: string;
  mobile_phone: string;
  office_phone: string;
  company: string;
  sub_company: string;
  personnel_category: number;
  discipline: string;
  discipline_category: string;
  special_role: string;
  permissions: string;
  start_date: string;
  end_date: string;
  status: string;
  srs_package: string;
  atc1_package: string;
  atc2_package: string;
  eg_package: string;
  as_package: string;
  notes: string;
  justification: string;
  educational_background: string;
  years_of_experience: number;
  cv: string;
  timestamp: any;
}
