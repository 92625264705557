import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from 'src/app/services/mail.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Users } from '../../types/user';
import { CompaniesService } from 'src/app/services/companies.service';
import { OnboardingRecordsService } from 'src/app/services/onboarding-records.service';
import { SubCompaniesService } from 'src/app/services/sub-companies.service';
import { PersonnelCategoryService } from 'src/app/services/personnel_category.service';
import { DisciplinesService } from 'src/app/services/disciplines.service';
import { DisciplineCategoryService } from 'src/app/services/discipline-category.service';
import { SpecialRoleService } from 'src/app/services/special-role.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { StatusService } from 'src/app/services/status.service';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import moment from 'moment';
import { StatusRecordsService } from 'src/app/services/status-records.service';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';

@Component({
  selector: 'app-newEditUser',
  templateUrl: './new-edit-user.component.html',
  styleUrls: ['./new-edit-user.component.scss']
 })
export class NewEditComponent implements OnInit {
  public currentUser: Observable<Users>;
  @Input() action: string;
  @Input() editUser: number;

  todayDate:Date = new Date();

  mode = "Create New";
  modeSubmit = "Submit";
  delUser = false;
  projectTypeClicked = false;
  testing = false;
  isSubmitBtnDisabled = false;

  statusRecords: any = [];
  companies: any = [];
  subCompanies: any = [];
  personnelCategories: any = [];
  personnelCategoriesBackup= [{name:'Category 1: Additional [COI 1]'}, {name:'Category 2: Additional [COI 2]'}, {name:'Category 3: Key Personnel [COI 3]'}];
  disciplines: any = [];
  disciplineCategories: any = [];
  specialRoles: any = [];
  permissions: any = [];
  statuses: any = [];
  myMaterials: any;
  thisUser: '';
  myCategory: any = '';
  //myCompany: any = '';
  myCompany: Observable<string[]>;
  disciplineLeads= [];
  cv: null;
  csls = [];
  autocompleteUsers = [];

  //newEditForm = null;
  newEditForm = this.formBuilder.group({
    fname: new FormControl('', Validators.required),
    lname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    mobile_phone: '',
    office_phone: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    sub_company: '',
    personnel_category: new FormControl('', Validators.required),
    discipline: new FormControl('', Validators.required),
    discipline_category: new FormControl('', Validators.required),
    special_role: new FormControl('', Validators.required),
    permissions: new FormControl('', Validators.required),
    start_date: new FormControl('', Validators.required),
    end_date: '',
    srs_package: false,
    atc1_package: false,
    atc2_package: false,
    eg_package: false,
    as_package: false,
    status: '',
    notes: new FormControl('', Validators.required),
    timestamp: '',
    cv: null,
    years_of_experience: '',
    educational_background: ''
  });

  startDate = new Date(2023, 0, 1);
  activeProj = 'srs';
  srs=false;
  ato=false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private user: UserService,
        private companyService: CompaniesService,
        private subCompanyService: SubCompaniesService,
        private personnelService: PersonnelCategoryService,
        private discplineService: DisciplinesService,
        private discplineCatService: DisciplineCategoryService,
        private specialRoleService: SpecialRoleService,
        private permissionsService: PermissionsService,
        private statusService: StatusService,
        private router: Router,
        private mail: MailService,
        private alertService: AlertService,
        private statusRecordsService: StatusRecordsService,
        private onBoardRecordService: OnboardingRecordsService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
    ) {
      this.currentUser = this.authenticationService.currentUserValue;

      this.todayDate.setDate(this.todayDate.getDate() + 7);

    }

    async ngOnInit() {
      let cslCount = 73
      for(let i=1; i<cslCount; i++){
        this.csls.push(i);
      }

      //companies
      await this.companyService.getAll().subscribe(res => this.companies = res );
      //sub-companies
      await this.subCompanyService.getAll().subscribe(res => this.subCompanies = res );
      //personnel-category
      await this.personnelService.getAll().subscribe(res => this.personnelCategories = res );
      //disciplines
      await this.discplineService.getAll().subscribe(res => this.disciplines = res );
      //discipline-category
      await this.discplineCatService.getAll().subscribe(res => this.disciplineCategories = res );
      //special-role
      await this.specialRoleService.getAll().subscribe(res => this.specialRoles = res );
      //permissions
      await this.permissionsService.getAll().subscribe(res => this.permissions = res );
      //status
      await this.statusService.getAll().subscribe(res => this.statuses = res );

      await this.discplineService.getAll().subscribe( res => {
        res.map(lead => {
          if(lead != null && lead.lead != null){
            this.disciplineLeads.push(lead);
          }
        });
        this.disciplineLeads.sort((a, b) => a.lead.normalize().localeCompare(b.lead.normalize()));
      });

      if(this.editUser != null) {

        this.newEditForm = this.formBuilder.group({
          fname: new FormControl('', Validators.required),
          lname: new FormControl('', Validators.required),
          email: new FormControl('', Validators.required),
          mobile_phone: '',
          office_phone: '',
          company: new FormControl('', Validators.required),
          sub_company: '',
          personnel_category: new FormControl('', Validators.required),
          discipline: new FormControl('', Validators.required),
          discipline_category: new FormControl('', Validators.required),
          special_role: new FormControl('', Validators.required),
          permissions: new FormControl('', Validators.required),
          start_date: new FormControl('', Validators.required),
          end_date: new FormControl('', Validators.required),
          srs_package: false,
          atc1_package: false,
          atc2_package: false,
          eg_package: false,
          as_package: false,
          status: '',
          notes: new FormControl('', Validators.required),
          timestamp: '',
          cv: null,
          years_of_experience: '',
          educational_background: ''
        });




        this.user.get(this.editUser).subscribe(res => {

          this.cv = res.cv;

          if(this.personnelCategories.length == 0){
            this.personnelCategories = this.personnelCategoriesBackup;
          }

          this.newEditForm.controls['fname'].setValue(res.name.split(', ')[1]);
          this.newEditForm.controls['lname'].setValue(res.name.split(', ')[0]);
          this.newEditForm.controls['email'].setValue(res.email);
          this.newEditForm.controls['mobile_phone'].setValue(res.mobile_phone);
          this.newEditForm.controls['office_phone'].setValue(res.office_phone);
          this.newEditForm.controls['company'].setValue(res.company);
          this.newEditForm.controls['sub_company'].setValue(res.sub_company);
          this.newEditForm.controls['personnel_category'].setValue(this.personnelCategories[res.personnel_category - 1].name);
          this.newEditForm.controls['discipline'].setValue(res.discipline);
          this.newEditForm.controls['discipline_category'].setValue(res.discipline_category);
          this.newEditForm.controls['special_role'].setValue(res.special_role);
          this.newEditForm.controls['permissions'].setValue(res.permissions);
          this.newEditForm.controls['start_date'].setValue(this.dateAdjust(res.start_date));
          this.newEditForm.controls['end_date'].setValue(this.dateAdjust(res.end_date));
          this.newEditForm.controls['status'].setValue(res.status);
          this.newEditForm.controls['notes'].setValue(res.notes);
          this.newEditForm.controls['srs_package'].setValue(res.srs_package);
          this.newEditForm.controls['atc1_package'].setValue(res.atc1_package);
          this.newEditForm.controls['atc2_package'].setValue(res.atc2_package);
          this.newEditForm.controls['eg_package'].setValue(res.eg_package);
          this.newEditForm.controls['as_package'].setValue(res.as_package);
          this.newEditForm.controls['cv'].setValue(res.cv);
          this.newEditForm.controls['years_of_experience'].setValue(res.years_of_experience);
          this.newEditForm.controls['educational_background'].setValue(res.educational_background);
          //this.newEditForm.controls['CSL_first'].setValue('CSL-'+ this.zeroPad(parseInt(res.CSL_first), 3));

          this.myCategory = res.personnel_category;
          this.myCompany = res.company;

          this.onBoardRecordService.findByEmail(res.email).subscribe(res => {
            this.myMaterials = res;

            if(this.action == "edit"){
              this.mode = "Edit";
              this.modeSubmit = "Update";
            } else if(this.action == "duplicate"){
              this.mode = "Duplicate";
              this.modeSubmit = "Submit";
            }
          });
        });

      }else {
        this.newEditForm = this.formBuilder.group({
          fname: new FormControl('', Validators.required),
          lname: new FormControl('', Validators.required),
          email: new FormControl('', Validators.required),
          mobile_phone: '',
          office_phone: '',
          company: new FormControl('', Validators.required),
          sub_company: '',
          personnel_category: new FormControl('', Validators.required),
          discipline: new FormControl('', Validators.required),
          discipline_category: new FormControl('', Validators.required),
          special_role: new FormControl('', Validators.required),
          permissions: new FormControl('', Validators.required),
          start_date: new FormControl('', Validators.required),
          end_date: '',
          srs_package: false,
          atc1_package: false,
          atc2_package: false,
          eg_package: false,
          as_package: false,
          status: '',
          notes: new FormControl('', Validators.required),
          timestamp: '',
          cv: null,
          years_of_experience: '',
          educational_background: ''
        });
      }
    }

    dateAdjust(date) {
      return moment(date).format("YYYY-MM-DD");
    }

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    }

    onSubmit(): void {
      this.isSubmitBtnDisabled = true;
      setTimeout(() => {
        console.log('re-enabling button')
        this.isSubmitBtnDisabled = false;
      },10000);

      this.alertService.clear();

      if (!this.newEditForm.valid) {
        // show errors
        this.validateAllFormFields(this.newEditForm);
        console.log('What were you thinking?');

        this.alertService.error('User details are incomplete!', true);
      } else {
        console.log('Looks like everything that is required is there, permission to move on!');
        if(!this.delUser){
          if(this.newEditForm.value.status == null){
            this.newEditForm.value.status = 'Approved';
          }

          const data = {
            'name': this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
            'email': this.newEditForm.value.email,
            'mobile_phone': this.newEditForm.value.mobile_phone,
            'office_phone': this.newEditForm.value.office_phone,
            'company': this.newEditForm.value.company,
            'sub_company': this.newEditForm.value.sub_company,
            'personnel_category': parseInt(this.newEditForm.value.personnel_category.split(' ')[1].replace(':', '')),
            'discipline': this.newEditForm.value.discipline,
            'discipline_category': this.newEditForm.value.discipline_category,
            'special_role': this.newEditForm.value.special_role,
            'permissions': this.newEditForm.value.permissions,
            'start_date': this.newEditForm.value.start_date,
            'end_date': this.newEditForm.value.end_date,
            'status': this.newEditForm.value.status,
            'notes': this.newEditForm.value.notes,
            'srs_package': (this.newEditForm.value.srs_package ? 1 : 0),
            'atc1_package': (this.newEditForm.value.atc1_package ? 1 : 0),
            'atc2_package': (this.newEditForm.value.atc2_package ? 1 : 0),
            'eg_package': (this.newEditForm.value.eg_package ? 1 : 0),
            'as_package': (this.newEditForm.value.as_package ? 1 : 0),
            'active': 1,
            'cv': this.newEditForm.value.cv,
            'years_of_experience': this.newEditForm.value.years_of_experience,
            'educational_background': this.newEditForm.value.educational_background,
            //'CSL_first': 'CSL-'+ this.zeroPad(parseInt(this.newEditForm.value.CSL_first), 3),
          };

          this.thisUser = this.newEditForm.value.email;
          if(this.editUser != null) {
            // edit
            this.updateUser(data);
          }else{
            // new
            this.createNewUser(data);
          }
        }else {
          this.deleteUser();
        }
      }
    }

    validateAllFormFields(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
        }
      });
    }

    manageMaterials(materials) {
      this.myMaterials = materials.value;
    }

    toggleDelete() {
      this.delUser = true;
    }

    deleteUser() {
      this.user.delete(this.editUser).subscribe(async res => {
        const subject = "You\'ve been removed from the Major Projects Tool for on-boarding!";
        const message = "Hello "+this.newEditForm.value.name+", For some reason you have been removed from the tool, sorry but we will miss you.";

        this.mail.sendContactEmail(this.newEditForm.value.email, subject, message).subscribe(res => {
          this.alertService.success('User has been deleted!', true);
          console.log(res)
        });
      });

      console.warn('Your order has been submitted', this.newEditForm.value);
    }

    updateUser(data) {
      console.log(this.editUser)

      this.user.update(this.editUser, data).subscribe(async res => {
        this.alertService.success('User has been updated!', true);

        //Check pending approval records
        this.statusRecordsService.findByUser(this.editUser).subscribe(res => {
          this.statusRecords = res;

          console.log(this.statusRecords);

          // Clean this up later
          if(data.srs_package == 1 && this.statusRecords.some(el => el.package === 'SRS')){
            //no update
            console.log('srs found & active');
          }else if(data.srs_package == 0 && this.statusRecords.some(el => el.package === 'SRS')){
            // delete record
            console.log('delete srs & non-active');
            let id = this.statusRecords.find(el => {
              if (el.package === 'SRS')
                return el.id;
            });
            id = id.id;
            this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
          }else if(data.srs_package == 1 && !this.statusRecords.some(el => el.package === 'SRS')){
            // create
            console.log('create srs & active');
            let dataPackage = {
              id: this.editUser,
              name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
              package: 'SRS',
              status: 'Pending Approval'
            }
            this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
          }

          if(data.atc1_package == 1 && this.statusRecords.some(el => el.package === 'ATC1')){
            //no update
            console.log('act1 found & active');
          }else if(data.atc1_package == 0 && this.statusRecords.some(el => el.package === 'ATC1')){
            // delete record
            console.log('delete atc1 & non-active');
            let id = this.statusRecords.find(el => {
              if (el.package === 'ATC1')
                return el.id;
            });
            id = id.id;
            this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
          }else if(data.atc1_package == 1 && !this.statusRecords.some(el => el.package === 'ATC1')){
            // create
            console.log('create atc1 & active');
            let dataPackage = {
              id: this.editUser,
              name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
              package: 'ATC1',
              status: 'Pending Approval'
            }
            this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
          }

          if(data.atc2_package == 1 && this.statusRecords.some(el => el.package === 'ATC2')){
            //no update
            console.log('atc2 found & active');
          }else if(data.atc2_package == 0 && this.statusRecords.some(el => el.package === 'ATC2')){
            // delete record
            console.log('delete atc2 & non-active');
            let id = this.statusRecords.find(el => {
              if (el.package === 'ATC2')
                return el.id;
            });
            id = id.id;
            this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
          }else if(data.atc2_package == 1 && !this.statusRecords.some(el => el.package === 'ATC2')){
            // create
            console.log('create atc2 & active');
            let dataPackage = {
              id: this.editUser,
              name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
              package: 'ATC2',
              status: 'Pending Approval'
            }
            this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
          }

          if(data.eg_package == 1 && this.statusRecords.some(el => el.package === 'EG')){
            //no update
            console.log('eg found & active');
          }else if(data.eg_package == 0 && this.statusRecords.some(el => el.package === 'EG')){
            // delete record
            console.log('delete e & non-active');
            let id = this.statusRecords.find(el => {
              if (el.package === 'EG')
                return el.id;
            });
            id = id.id;
            this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
          }else if(data.eg_package == 1 && !this.statusRecords.some(el => el.package === 'EG')){
            // create
            console.log('create eg & active');
            let dataPackage = {
              id: this.editUser,
              name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
              package: 'EG',
              status: 'Pending Approval'
            }
            this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
          }

          if(data.as_package == 1 && this.statusRecords.some(el => el.package === 'AS')){
            //no update
            console.log('as found & active');
          }else if(data.as_package == 0 && this.statusRecords.some(el => el.package === 'AS')){
            // delete record
            console.log('delete as & non-active');
            let id = this.statusRecords.find(el => {
              if (el.package === 'AS')
                return el.id;
            });
            id = id.id;
            this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
          }else if(data.as_package == 1 && !this.statusRecords.some(el => el.package === 'AS')){
            // create
            console.log('create as & active');
            let dataPackage = {
              id: this.editUser,
              name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
              package: 'AS',
              status: 'Pending Approval'
            }
            this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
          }
        });

        this.myMaterials.map(item => {
          if(item.id == 0) {
            this.createMaterialRecords(item);
          }else {
            this.updateMaterialRecords(item);
          }

        });
      });

      console.warn('Your order has been submitted', this.newEditForm.value);
    }

    async createNewUser(data) {

      //Chheck if email exists already
      await this.user.findByEmail( data.email.toLowerCase() ).subscribe(res => {

        let leader = null;
        let leaderEmail = null;
        this.disciplineLeads.map(d => {
          if(d.name == data.discipline) {
            leader = d.lead;
            leaderEmail = d.lead.toLowerCase().replace(' ', '.') + "@arup.com";
          }
        });

        if(!this.testing) {
        if(res[0] == null) {
          this.user.create(data).subscribe(async myRes => {
            console.log(myRes);

            this.alertService.success('User has been added and notified!', true);

            const subject = "New user has been created in the Major Projects Tool for on-boarding!";
            const message = "Hello Sophia & "+leader+", "+data.name+" has been added to the Major Projects Tool.";

            this.mail.sendContactEmail('anddrew.phillips-g@arup.com, sophia.campbell@arup.com,'+leaderEmail+'', subject, message);

            this.myMaterials.map(item => {
              this.createMaterialRecords(item);
            });


            //Check pending approval records
            this.statusRecordsService.findByUser(this.editUser).subscribe(res => {
              this.statusRecords = res;

              console.log(this.statusRecords);

              // Clean this up later
              if(data.srs_package == 1 && this.statusRecords.some(el => el.package === 'SRS')){
                //no update
                console.log('srs found & active');
              }else if(data.srs_package == 0 && this.statusRecords.some(el => el.package === 'SRS')){
                // delete record
                console.log('delete srs & non-active');
                let id = this.statusRecords.find(el => {
                  if (el.package === 'SRS')
                    return el.id;
                });
                id = id.id;
                this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
              }else if(data.srs_package == 1 && !this.statusRecords.some(el => el.package === 'SRS')){
                // create
                console.log('create srs & active');
                let dataPackage = {
                  id: myRes.id,
                  name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
                  package: 'SRS',
                  status: 'Pending Approval'
                }
                this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
              }

              if(data.atc1_package == 1 && this.statusRecords.some(el => el.package === 'ATC1')){
                //no update
                console.log('act1 found & active');
              }else if(data.atc1_package == 0 && this.statusRecords.some(el => el.package === 'ATC1')){
                // delete record
                console.log('delete atc1 & non-active');
                let id = this.statusRecords.find(el => {
                  if (el.package === 'ATC1')
                    return el.id;
                });
                id = id.id;
                this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
              }else if(data.atc1_package == 1 && !this.statusRecords.some(el => el.package === 'ATC1')){
                // create
                console.log('create atc1 & active');
                let dataPackage = {
                  id: myRes.id,
                  name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
                  package: 'ATC1',
                  status: 'Pending Approval'
                }
                this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
              }

              if(data.atc2_package == 1 && this.statusRecords.some(el => el.package === 'ATC2')){
                //no update
                console.log('atc2 found & active');
              }else if(data.atc2_package == 0 && this.statusRecords.some(el => el.package === 'ATC2')){
                // delete record
                console.log('delete atc2 & non-active');
                let id = this.statusRecords.find(el => {
                  if (el.package === 'ATC2')
                    return el.id;
                });
                id = id.id;
                this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
              }else if(data.atc2_package == 1 && !this.statusRecords.some(el => el.package === 'ATC2')){
                // create
                console.log('create atc2 & active');
                let dataPackage = {
                  id: myRes.id,
                  name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
                  package: 'ATC2',
                  status: 'Pending Approval'
                }
                this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
              }

              if(data.eg_package == 1 && this.statusRecords.some(el => el.package === 'EG')){
                //no update
                console.log('eg found & active');
              }else if(data.eg_package == 0 && this.statusRecords.some(el => el.package === 'EG')){
                // delete record
                console.log('delete e & non-active');
                let id = this.statusRecords.find(el => {
                  if (el.package === 'EG')
                    return el.id;
                });
                id = id.id;
                this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
              }else if(data.eg_package == 1 && !this.statusRecords.some(el => el.package === 'EG')){
                // create
                console.log('create eg & active');
                let dataPackage = {
                  id: myRes.id,
                  name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
                  package: 'EG',
                  status: 'Pending Approval'
                }
                this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
              }

              if(data.as_package == 1 && this.statusRecords.some(el => el.package === 'AS')){
                //no update
                console.log('as found & active');
              }else if(data.as_package == 0 && this.statusRecords.some(el => el.package === 'AS')){
                // delete record
                console.log('delete as & non-active');
                let id = this.statusRecords.find(el => {
                  if (el.package === 'AS')
                    return el.id;
                });
                id = id.id;
                this.statusRecordsService.delete(id).subscribe(res => { console.log(res)});
              }else if(data.as_package == 1 && !this.statusRecords.some(el => el.package === 'AS')){
                // create
                console.log('create as & active');
                let dataPackage = {
                  id: myRes.id,
                  name: this.newEditForm.value.lname + ', ' + this.newEditForm.value.fname,
                  package: 'AS',
                  status: 'Pending Approval'
                }
                this.statusRecordsService.create(dataPackage).subscribe(res => { console.log(res)});
              }
            });


            setTimeout( ()=> {
              let link = '/tool-manager/edit/' + myRes.id;
              this.router.navigate([link]);
            }, 3000);

          });

        }else {
          this.alertService.error('User email already exists!', true);

          this.user.findByEmail( data.email.toLowerCase() ).subscribe(res => {
            console.log(res[0].id)
            setTimeout( ()=> {
              let link = '/tool-manager/edit/' + res[0].id;
              this.router.navigate([link]);
            }, 3000);
          });
        }
        }

      });



    }

    createMaterialRecords(item) {
      if(item.include) {
        let record = {
          user: this.thisUser,
          onboarding_material: item.name,
          status: item.status,
          expiration_date: item.expiration_date,
          material_type: item.type,
          assignee: item.assignee,
          url: item.url
        }
        this.onBoardRecordService.create(record).subscribe(async res => {
          // email notifcation ?
          console.log('made this record:: ', res)
        });
      }
    }

    updateMaterialRecords(item) { // UPDATE FOR DELETING RECORDS THAT WERE UNCHECKED
      if(item.include) {
        let id = item.id;
        let record = {
          user: this.thisUser,
          onboarding_material: item.name,
          status: item.status,
          expiration_date: item.expiration_date,
          material_type: item.type,
          assignee: item.assignee,
          url: item.url
        }
        this.onBoardRecordService.update(id,record).subscribe(async res => {
          // email notifcation ?
          console.log('made this record:: ', res)
        });
      }
    }

    toggleProject(project) {
      this.activeProj = project;
    }

    /* user auth AAD */

    async suggestUsers(searching) {
      if (!searching) { return; }
      await this.authenticationService.searchUsers(searching);
      const usersObj = Object.values(this.authenticationService.searching);

      let foundUsers = null;
      // Gets User Array object, sometimes it comes back with 3 or 2 values, one item being the array.
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < usersObj.length; i++) {
        if (usersObj[i].constructor === Array) {
          foundUsers = usersObj[i];
        }
      }
      const users = [];
      if (foundUsers && foundUsers.length > 0) {
        for ( const user of foundUsers) {
          users.push({
            id: user.id,
            name: user.displayName,
            fname: user.givenName,
            lname: user.surname,
            email: user.userPrincipalName, //email
            office_phone: user.businessPhones[0],
            mobile_phone: user.mobilePhone,
            company: 'Arup',
            location: user.officeLocation
          });
        }
        this.autocompleteUsers = users;
      }
    }

    autoUser(selected) {
      let res = selected.value;

      this.newEditForm.controls['fname'].setValue(res.fname);
      this.newEditForm.controls['lname'].setValue(res.lname);
      this.newEditForm.controls['email'].setValue(res.email);
      this.newEditForm.controls['mobile_phone'].setValue(res.mobile_phone);
      this.newEditForm.controls['office_phone'].setValue(res.office_phone);
      this.newEditForm.controls['company'].setValue(res.company);

    }

    updateCompany(name) {
      this.myCompany = name;
    }

    updateCat(name) {
      let val = name.split(" ");
      this.myCategory = parseInt(val[1].replace(':',''));
    }

    updateSRScontract(val){
      this.srs = val;
    }

    updateATOcontract(val){
      this.ato = val;
    }

    ngAfterContentChecked(): void {
      this.cdr.detectChanges();
   }

   updateUploadValue(e) {
    console.log(e.val, e)
    this.newEditForm.controls['cv'].setValue(e.val);
  }
}
