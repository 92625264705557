import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DialogService } from '../../services/dialog.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Users } from '../../types/user';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StatusRecordsService } from 'src/app/services/status-records.service';
import { DisciplinesService } from 'src/app/services/disciplines.service';

@Component({
  selector: 'app-userProfileShort',
  templateUrl: './user-profile-short.component.html',
  styleUrls: ['./user-profile-short.component.scss']
 })
export class UserProfileShortComponent implements OnInit {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  //public users: Observable<Users>;

  users: any;

  @Input() approveUser: string;
  @Input() userList: any;
  @Input() project: any;

  root = environment.root;

  loaded = false;
  progress: number = 0;
  profile = false;
  list = false;
  approval = false;
  history = false;
  csl = false;
  activeUser: any;
  usersSet: any;
  userStatuses = [];
  isLead = false;
  lead = null;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private UserService: UserService,
        private statusRecords: StatusRecordsService,
        private DialogService: DialogService,
        private DisciplineService: DisciplinesService,
        private authenticationService: AuthenticationService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.DisciplineService.findByEmail(this.currentUser['discipline']).subscribe(res => {
          if(this.currentUser['name'] == res[0].lead){
            this.isLead = true;
            this.lead = res[0].lead;
          }
        });

    }

    @ViewChild(MatSort, {static:false}) sort: MatSort;
    //get statuses
    ngOnInit() {

          if(this.approveUser!=null) {
            this.loaded = true;

            this.userList.pipe(res => {
              res.subscribe(r => {
                console.log(r)
              })
              //if(this.isLead){
                //this.usersSet = this.filterForDiscLead(res, 'approved');
              //}else{
                this.usersSet = res;
              //}
            });

          } else {
            this.usersSet = this.UserService.getAll().pipe(
              tap( res => {this.loaded = true }),
              map( res => {
                res.map(r => {
                  r.expanded = false;
                  r.list = false;
                  r.profile = false;
                  r.approval = false;
                });

                if(this.isLead){
                  return this.filterForDiscLead(res, 'staff');
                }else{
                  return res;
                }
              })
            );



            this.usersSet.subscribe(r => {
              let approved_csl = [];
              let demobilized = [];
              let rejected = [];
              let nully = [];

              r.map((user,i)=> {
                if(user.status == 'Approved'){
                  approved_csl.push(user)
                }else if(user.status == 'Demobilized'){
                  demobilized.push(user)
                }else if(user.status == 'Not Approved'){
                  rejected.push(user)
                }else if(user.status == '' || user.status == null){
                  nully.push(user)
                }
              })
              console.log('approved_csl COUNT: ' + approved_csl.length);
              console.log('demobilized COUNT: ' + demobilized.length);
              console.log('rejected COUNT: ' + rejected.length);
              console.log('null COUNT: ' + nully.length);
              this.users = approved_csl;
            });

          }

     if(this.currentUser != null){

      this.statusRecords.findByUserTags(this.currentUser['id']).subscribe(res => {

        res.map(r => {
          r.color = r.status.replace(/\s+/g, '');
          r.pack = r.package.toLowerCase() + '_package';
        })
        this.userStatuses = res;
      });
     }


    }

    filterForDiscLead(result, section){

      if(section == 'staff'){
        if(result != null || result.length != 0){
          let newlist = [];
          result.map(user => {
            if(user.discipline == this.currentUser['discipline']){
              newlist.push(user);
            }
          });
          return newlist;
        }else {
          return [];
        }
      }else{
        if(result != null || result.length != 0){
          let newlist = [];
          result.subscribe(r => {
            r.map(user => {
              if(user.discipline == this.currentUser['discipline']){
                newlist.push(user);
              }
            });
          });

          return newlist;
        }else {
          return [];
        }
      }


    }

    openDialog(type, title, body, user){
      const dialog = {
        title: title,
        body: body,
        type: type,
        user: user
      }
      this.DialogService.openDialog('',dialog);
    }

    updateUsers(event) {
      this.usersSet = of(event)
    }

    closeAll(user) {

      if(this.activeUser != null){
        this.activeUser.expanded = false;
      }

      if(this.activeUser != null && this.activeUser == user){
        user.expanded = false;
        this.list = false;
        this.profile = false;
        this.history = false;
        this.approval = false;
        this.csl = true;
      }

      this.activeUser = user;
    }

    showProgress(user) {
      let isOpen = user.expanded;
      this.closeAll(user);

      if (isOpen == true && this.list) {
        user.expanded = false;
        this.list = false;
        return;
      }

      user.expanded = true;
      this.list = true;
      this.profile = false;
      this.history = false;
      this.approval = false;
      this.csl = false;
    }

    showProfile(user) {
      let isOpen = user.expanded;
      this.closeAll(user);

      if (isOpen == true && this.profile) {
        user.expanded = false;
        this.profile = false;
        return;
      }

      user.expanded = true;
      this.list = false;
      this.profile = true;
      this.history = false;
      this.approval = false;
      this.csl = false;
    }

    showApproval(user) {
      let isOpen = user.expanded;
      this.closeAll(user);

      if (isOpen == true && this.approval) {
        user.expanded = false;
        this.approval = false;
        return;
      }

      user.expanded = true;
      this.list = false;
      this.profile = false;
      this.history = false;
      this.csl = false;
      this.approval = true;
    }

    showCSL(user) {
      let isOpen = user.expanded;
      this.closeAll(user);

      if (isOpen == true && this.approval) {
        user.expanded = false;
        this.approval = false;
        return;
      }

      user.expanded = true;
      this.list = false;
      this.profile = false;
      this.history = false;
      this.approval = false;
      this.csl = true;
    }

    showHistory(user) {
      let isOpen = user.expanded;
      this.closeAll(user);

      if (isOpen == true && this.history) {
        user.expanded = false;
        this.history = false;
        return;
      }

      user.expanded = true;
      this.list = false;
      this.profile = false;
      this.history = true;
      this.approval = false;
      this.csl = false;
    }
}
