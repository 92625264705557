import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Users } from '../../types/user';


@Component({
  selector: 'app-admin',
  templateUrl: 'admin.component.html',
  styleUrls: ['./admin.component.scss']
 })
export class AdminComponent implements OnInit {
    currentUser: Users;

    tools = [
      {name:'Companies', value:'companies'},
      {name:'Sub Companies', value:'sub_companies'},
      {name:'Discipline Categories', value:'discipline_categories'},
      {name:'Disciplines', value:'disciplines'},
      {name:'Onboarding Material Type', value:'onboarding_material_type'},
      // {name:'Onboarding Materials', value:'onboarding_materials'},
      {name:'Permissions', value:'permissions'},
      {name:'Special Roles', value:'special_roles'},
      {name:'Statuses', value:'statuses'},
    ]

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        } else {
            this.currentUser = this.authenticationService.currentUserValue;
        }
    }

    ngOnInit() {

    }
}
