import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder } from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource, } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { StatusRecordsService } from 'src/app/services/status-records.service'
import { DialogService } from '../../services/dialog.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { environment } from 'src/environments/environment';
import { HttpParameterCodec } from '@angular/common/http';

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
      return encodeURIComponent(key);
  }
  encodeValue(key: string): string {
      return encodeURIComponent(key);
  }
  decodeKey(key: string): string {
      return decodeURIComponent(key);
   }
  decodeValue(key: string) {
      return decodeURIComponent(key);
   }
}

export interface materialsList {
  name: string;
  type: string;
  status: string;
  expiration_date: string;
  last_updated: string;
  url: string;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
 })
export class HistoryComponent implements OnInit, AfterViewInit {
  public currentUserSubject: BehaviorSubject<Users>;
  public currentUser: Observable<Users>;

  contracts: string[] = ["SRS", "ATC1", "ATC2", "EG","AS"];
  statuses: string[] = ["New Addition", "Demobilized", "Remobilization"];
  displayedColumns: string[] = ['name', 'date', 'status', 'package'];
  dataSource2 = new MatTableDataSource<materialsList>();

  toDeMob = false;
  csls = [];


  @Input() showUser: number;
  @Input() approval: boolean;

  root = environment.root;

  statusRecordForm = this.formBuilder.group({
    package: '',
    CSL_end: '',
    status: '',
  });

  packages = [
    'SRS',
    'ATC1',
    'ATC2',
    'EG',
    'AS'
  ];

  user: any;
  moodleMaterials = [];
  email: any;
  totalCompleted = 0;
  moodleToken = null;
  submitted = false;
  newStatus: string;
  newPackage: string;

  @ViewChild(MatPaginator, { static: false}) paginator: MatPaginator;

    constructor(
        private formBuilder: FormBuilder,
        private UserService: UserService,
        private StatusRecordsService: StatusRecordsService,
        private DialogService: DialogService,
        private authenticationService: AuthenticationService,
    ) {
      this.currentUser = this.authenticationService.currentUserValue;
      this.UserService.getAZ({}).subscribe(res => {
        this.moodleToken = res;
      });
      //moodle token end

    }

    ngOnInit() {
      let cslCount = 73
      for(let i=1; i<cslCount; i++){
        this.csls.push(i);
      }

      if (this.showUser != null) {
        this.StatusRecordsService.findByUser(this.showUser).subscribe(res => {
            //res.sort((a,b) => a.date.localeCompare(b.date));
            this.user = res;
            this.email =this.user.email;
            this.dataSource2.data = this.user;
            this.dataSource2.paginator = this.paginator;
          });

      }else {
        let e = JSON.parse(JSON.stringify(this.currentUser));
        this.UserService.findByEmail(e.email).subscribe(res => {
          this.user = res[0];
          this.email =this.user.email;
          this.dataSource2.data = this.user;
          this.dataSource2.paginator = this.paginator;
        });
      }
    }

    checkDeMob(value) {
      if(value == 'Demobilized'){
        this.toDeMob = true;
      }else{
        this.toDeMob = false;
      }
    }

    getDate() {
      let today = new Date();
      let day = '';
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = 0 + dd;
      if (mm < 10) mm = 0 + mm;

      return day = yyyy + '-' + mm + '-' + dd;
    }


    openDialog(type, title, body){
      const dialog = {
        title: title,
        body: body,
        type: type
      }
      this.DialogService.openDialog('',dialog);
    }

    getMaterials() {
      //this.dataSource2 = new MatTableDataSource<any>(this.user);

      //this.dataSource2.paginator = this.paginator;
    }

    createNewUserStatus(data) {
      this.StatusRecordsService.create(data).subscribe(async res => {

        this.submitted = true;
        this.statusRecordForm.reset();
      });
    }


    onSubmit(mode): void {
      if(mode=='all'){
        for(let i =0; i< this.packages.length; i++) {
          const data = {
            'id': this.user[0].id,
            'name': this.user[0].name,
            'package': this.packages[i],
            'status': this.statusRecordForm.value.status,
            'CSL_end': this.statusRecordForm.value.CSL_end,
            'date': this.getDate(),
          }
          console.log(data)
          this.createNewUserStatus(data);
        }
      }else{
          const data = {
            'id': this.user[0].id,
            'name': this.user[0].name,
            'package': this.statusRecordForm.value.package,
            'status': this.statusRecordForm.value.status,
            'CSL_end': this.statusRecordForm.value.CSL_end,
            'date': this.getDate(),
          }
          console.log(data)
          this.createNewUserStatus(data);
      }
    }

    ngAfterViewInit() {
      this.dataSource2.paginator = this.paginator;
    }
}
