import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { environment } from 'src/environments/environment';
import { DisciplinesService } from 'src/app/services/disciplines.service';

@Component({
  selector: 'app-userProfile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
 })
export class UserProfileComponent implements OnInit, AfterViewInit {
  public currentUserSubject: BehaviorSubject<Users>;
  public currentUser: Observable<Users>;

  @Input() showUser: number;
  @Input() loadedUser: Users;

  root = environment.root;

  user: any;

  displayedColumns: string[] = ['note', 'timestamp'];
  dataSource: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private UserService: UserService,
    private DisciplineService: DisciplinesService,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;

  }

  @ViewChild(MatSort, {static:false}) sort: MatSort;

  ngOnInit() {
    if(this.showUser==null){
      this.user = this.currentUser
    }else {
      this.user = this.loadedUser;
    }

    this.DisciplineService.findByEmail(this.user.discipline).subscribe(res => {
      this.user.disicpline_lead = res[0].lead;
      this.dataSource = new MatTableDataSource(this.user.notes);
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {


  }


    /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
