import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Users } from '../../types/user';


@Component({
  selector: 'app-existing-users',
  templateUrl: 'existing-users.component.html',
  styleUrls: ['./existing-users.component.scss']
 })
export class ExistingUsersComponent implements OnInit {
    currentUser: Users;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        } else {
            this.currentUser = this.authenticationService.currentUserValue;
        }
    }

    ngOnInit() {

    }
}
