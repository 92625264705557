import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AngularMaterialModule } from './angular-material.module';
import { MatAutocompleteModule, MatProgressBarModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// used to create fake backend
import { fakeBackendProvider } from './helpers/fake-backend';
import { ErrorInterceptor } from './helpers/error.interceptor';
//import { JwtInterceptor } from './helpers/jwt.interceptors';

//services
import { AlertService } from './services/alert.service';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';

// Components
import { AppComponent } from './app.component';
import { AlertComponent } from './components/alert/alert.component';
import { MyTasksComponent } from './containers/my-tasks/my-tasks.component';
import { ExistingUsersComponent } from './containers/existing-users/existing-users.component';
import { ToolManagerComponent } from './containers/tool-manager/tool-manager.component';
import { SupportComponent } from './containers/support/support.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ProfileOnboardingComponent } from './components/profile-onboarding/profile-onboarding.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { HistoryComponent } from './components/history/history.component';
import { FilterComponent } from './components/filter/filter.component';
import { UserProfileShortComponent } from './components/user-profile-short/user-profile-short.component';
import { TaskComponent } from './containers/task/task.component';
import { HomeComponent } from './containers/home/home.component';
import { NewUsersComponent } from './containers/new-users/new-users.component';
import { NewEditComponent } from './components/new-edit-user/new-edit-user.component';
import { ApproveUsersComponent } from './containers/approve-users/approve-users.component';
import { ApprovalComponent } from './components/approval/approval.component';
import { DialogModule } from './components/dialog/dialog.module';

//arc
import '@arc-web/components/dist/components/container/arc-container.js';
import '@arc-web/components/dist/components/navbar/arc-navbar.js';
import '@arc-web/components/dist/components/button/arc-button.js';
//import '@arc-web/components/dist/components/icon/arc-icon.js';
import '@arc-web/components/dist/components/icon-button/arc-icon-button.js';
import '@arc-web/components/dist/components/image/arc-image.js';
import '@arc-web/components/dist/components/card/arc-card.js';

// Azure
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalModule, MsalInterceptor, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { Logger, LogLevel } from 'msal';
import { AdminComponent } from './containers/admin/admin.component';
import { AdminNewEditComponent } from './components/admin-new-edit/admin-new-edit.component';
import { LandingComponent } from './containers/home/landing/landing.component';

import { NativeDateAdapter, DateAdapter,
  MAT_DATE_FORMATS } from '@angular/material';
import { formatDate } from '@angular/common';
import { MassContactComponent } from './components/mass-contact/mass-contact.component';
import { LeftPadFilter } from './helpers/leftPadZero';
import { NewEditCSLComponent } from './components/new-edit-cls-user/new-edit-csl-user.component';
import { FileUploadService } from './services/file-upload.service';
import { UploadFileComponent } from './components/upload-single/upload-single.component';
import { CSLUsersComponent } from './containers/csl-users/csl-users.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export function loggerCallback(logLevel, message, piiEnabled) {
  console.log(message);
}
export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
          return formatDate(date,'yyyy-MM-dd',this.locale);;
      } else {
          return date.toDateString();
      }
  }
}

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    LeftPadFilter,
    LandingComponent,
    AppComponent,
    HomeComponent,
    TaskComponent,
    MyTasksComponent,
    ExistingUsersComponent,
    ToolManagerComponent,
    SupportComponent,
    AlertComponent,
    UserProfileComponent,
    ProfileOnboardingComponent,
    ContactFormComponent,
    FilterComponent,
    UserProfileShortComponent,
    NewUsersComponent,
    NewEditCSLComponent,
    NewEditComponent,
    ApproveUsersComponent,
    OnboardingComponent,
    ApprovalComponent,
    AdminComponent,
    AdminNewEditComponent,
    HistoryComponent,
    MassContactComponent,
    UploadFileComponent,
    CSLUsersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    DialogModule,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: '293f711c-6d3c-413b-959d-d31d7b205878',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }}),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: ['user.read']
        }
      },
      {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']]
        ])
      }
    ),
    MatAutocompleteModule,
  ],
  exports: [
    ContactFormComponent
  ],
  entryComponents: [
    ContactFormComponent
  ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },

    // provider used to create fake backend
    //fakeBackendProvider,
    AlertService,
    AuthenticationService,
    UserService,
    FileUploadService
    // {
    //   provide: MSAL_CONFIG,
    //   useFactory: MSALConfigFactory
    // },
    // {
    //   provide: MSAL_CONFIG_ANGULAR,
    //   useFactory: MSALAngularConfigFactory
    // },
    // MsalService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
