import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { PersonnelCategory } from '../types/personnel-category';
import { environment } from '../../environments/environment';

const baseUrl = environment.apiUrl + '/personnel-categories';

@Injectable({ providedIn: 'root' })
export class PersonnelCategoryService {
  constructor(private http: HttpClient) { }
  getAll(): Observable<PersonnelCategory[]> {
    return this.http.get<PersonnelCategory[]>(baseUrl);
  }
  get(id: any): Observable<any> {
    return this.http.get(`${baseUrl}/${id}`);
  }
  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }
  update(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  }
  delete(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`);
  }
  deleteAll(): Observable<any> {
    return this.http.delete(baseUrl);
  }
  findByEmail(name: any): Observable<PersonnelCategory[]> {
    return this.http.get<PersonnelCategory[]>(`${baseUrl}?name=${name}`);
  }
}
