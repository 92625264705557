import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from 'src/app/services/mail.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Users } from '../../types/user';
import { StatusRecordsService } from 'src/app/services/status-records.service';
import { UserService } from 'src/app/services/user.service';
import moment from 'moment';


@Component({
  selector: 'app-approveUsers',
  templateUrl: './approve-users.component.html',
  styleUrls: ['./approve-users.component.scss']
 })
export class ApproveUsersComponent implements OnInit {
  public currentUser: Observable<any>;
  public usersSRS: Observable<any>;
  public usersATC1: Observable<any>;
  public usersATC2: Observable<any>;
  public usersEG: Observable<any>;
  public usersAS: Observable<any>;

  loaded = false;
  activeProj = 'srs';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private UserService: UserService,
        private statusRecords: StatusRecordsService,
        private router: Router,
        private mail: MailService,
        private authenticationService: AuthenticationService
    ) {
      this.currentUser = this.authenticationService.currentUserValue;

    }

    filterForDiscLead(result){
      return result;
      // console.log(result, this.currentUser);
      // if(result != null || result.length != 0){
      //     let newlist = [];
      //     result.map(user => {
      //       if(user.discipline == this.currentUser['discipline']){
      //         newlist.push(user);
      //       }
      //     });
      //     return newlist;
      //   }else {
      //     return [];
      //   }
    }

    ngOnInit() {
      this.loadit();
    }

    async loadit(){
      this.usersSRS = await this.statusRecords.findByStatus('Pending Approval','SRS').pipe(
        tap( res => {this.loaded = true }),
        map( res => {
          res.map( r  => {
            this.UserService.get(r.id).subscribe(res2 => {
              let user = res2;
              r.atc1_package = user.atc1_package;
              r.atc2_package = user.atc2_package;
              r.company = user.company;
              r.discipline = user.discipline;
              r.discipline_category = user.discipline_category;
              r.eg_package = user.eg_package;
              r.email = user.email;
              r.mobile_phone = user.mobile_phone;
              r.notes = user.notes;
              r.office_phone = user.office_phone;
              r.permissions = user.permissions;
              r.personnel_category = user.personnel_category;
              r.special_role = user.special_role;
              r.srs_package = user.srs_package;
              r.as_package = user.as_package;
              r.status = user.status;
              r.sub_company = user.sub_company;
            })
          });
          return this.filterForDiscLead(res);
        }),
        map( res => {
          res.map(r => {
            r.expanded = false;
          });
          return res
        })
      );

      this.usersATC1 = await this.statusRecords.findByStatus('Pending Approval','ATC1').pipe(
        tap( res => {this.loaded = true }),
        map( res => {
          res.map( r  => {
            this.UserService.get(r.id).subscribe(res2 => {
              let user = res2;
              r.atc1_package = user.atc1_package;
              r.atc2_package = user.atc2_package;
              r.company = user.company;
              r.discipline = user.discipline;
              r.discipline_category = user.discipline_category;
              r.eg_package = user.eg_package;
              r.email = user.email;
              r.mobile_phone = user.mobile_phone;
              r.notes = user.notes;
              r.office_phone = user.office_phone;
              r.permissions = user.permissions;
              r.personnel_category = user.personnel_category;
              r.special_role = user.special_role;
              r.srs_package = user.srs_package;
              r.as_package = user.as_package;
              r.status = user.status;
              r.sub_company = user.sub_company;
            })
          });
          return this.filterForDiscLead(res);
        }),
        map( res => {
          res.map(r => {
            r.expanded = false;
          });
          return res
        })
      );

      this.usersATC2 = await this.statusRecords.findByStatus('Pending Approval','ATC2').pipe(
        tap( res => {this.loaded = true }),
        map( res => {
          console.log(res)
          res.map( r  => {
            this.UserService.get(r.id).subscribe(res2 => {
              console.log(res2)
              let user = res2;
              r.atc1_package = user.atc1_package;
              r.atc2_package = user.atc2_package;
              r.company = user.company;
              r.discipline = user.discipline;
              r.discipline_category = user.discipline_category;
              r.eg_package = user.eg_package;
              r.email = user.email;
              r.mobile_phone = user.mobile_phone;
              r.notes = user.notes;
              r.office_phone = user.office_phone;
              r.permissions = user.permissions;
              r.personnel_category = user.personnel_category;
              r.special_role = user.special_role;
              r.srs_package = user.srs_package;
              r.as_package = user.as_package;
              r.status = user.status;
              r.sub_company = user.sub_company;
            })
          });
          return this.filterForDiscLead(res);
        }),
        map( res => {
          res.map(r => {
            r.expanded = false;
          });
          return res
        })
      );

      this.usersEG = await this.statusRecords.findByStatus('Pending Approval','EG').pipe(
        tap( res => {this.loaded = true }),
        map( res => {
          res.map( r  => {
            this.UserService.get(r.id).subscribe(res2 => {
              console.log(res2)
              let user = res2;
              r.atc1_package = user.atc1_package;
              r.atc2_package = user.atc2_package;
              r.company = user.company;
              r.discipline = user.discipline;
              r.discipline_category = user.discipline_category;
              r.eg_package = user.eg_package;
              r.email = user.email;
              r.mobile_phone = user.mobile_phone;
              r.notes = user.notes;
              r.office_phone = user.office_phone;
              r.permissions = user.permissions;
              r.personnel_category = user.personnel_category;
              r.special_role = user.special_role;
              r.srs_package = user.srs_package;
              r.as_package = user.as_package;
              r.status = user.status;
              r.sub_company = user.sub_company;
            })
          });
          return this.filterForDiscLead(res);
        }),
        map( res => {
          res.map(r => {
            r.expanded = false;
          });
          return res
        })
      );

      this.usersAS = await this.statusRecords.findByStatus('Pending Approval','AS').pipe(
        tap( res => {this.loaded = true }),
        map( res => {
          res.map( r  => {
            this.UserService.get(r.id).subscribe(res2 => {
              console.log(res2)
              let user = res2;
              r.atc1_package = user.atc1_package;
              r.atc2_package = user.atc2_package;
              r.company = user.company;
              r.discipline = user.discipline;
              r.discipline_category = user.discipline_category;
              r.eg_package = user.eg_package;
              r.email = user.email;
              r.mobile_phone = user.mobile_phone;
              r.notes = user.notes;
              r.office_phone = user.office_phone;
              r.permissions = user.permissions;
              r.personnel_category = user.personnel_category;
              r.special_role = user.special_role;
              r.srs_package = user.srs_package;
              r.as_package = user.as_package;
              r.status = user.status;
              r.sub_company = user.sub_company;
            })
          });
          return this.filterForDiscLead(res);
        }),
        map( res => {
          res.map(r => {
            r.expanded = false;
          });
          return res
        })
      );
    }

    toggleProject(project) {
      this.activeProj = project;
    }

}
