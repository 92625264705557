import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource, } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { DialogService } from '../../services/dialog.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OnboardingRecordsService } from 'src/app/services/onboarding-records.service';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { MatAccordion } from '@angular/material';

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
      return encodeURIComponent(key);
  }
  encodeValue(key: string): string {
      return encodeURIComponent(key);
  }
  decodeKey(key: string): string {
      return decodeURIComponent(key);
   }
  decodeValue(key: string) {
      return decodeURIComponent(key);
   }
}

export interface materialsList {
  name: string;
  type: string;
  status: string;
  expiration_date: string;
  // assignee: string;
  last_updated: string;
  actions: boolean;
  url: string;
}

@Component({
  selector: 'app-profileOnboarding',
  templateUrl: './profile-onboarding.component.html',
  styleUrls: ['./profile-onboarding.component.scss']
 })
export class ProfileOnboardingComponent implements OnInit, AfterViewInit {
  public currentUserSubject: BehaviorSubject<Users>;
  public currentUser: Observable<Users>;

  displayedColumns: string[] = ['name', 'type', 'status', 'expiration_date', 'last_updated', 'actions'];
  dataSource = new MatTableDataSource<materialsList>();

  @Input() showUser: number;

  root = environment.root;

  user: any;
  myMaterials= [];
  moodleMaterials= [];
  email: any;
  totalCompleted=0;
  moodleToken=null;
  moodrun=false;
  usersSet: any;
  users: any;

  @ViewChild(MatPaginator, { static: false}) paginator: MatPaginator;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private UserService: UserService,
        private DialogService: DialogService,
        private onBoardRecordService: OnboardingRecordsService,
        private authenticationService: AuthenticationService,
        private http: HttpClient
    ) {
      this.currentUser = this.authenticationService.currentUserValue;
      this.UserService.getAZ({}).subscribe(res => {
        this.moodleToken = res;
      });
      //moodle token end

    }

    ngOnInit() {

      if (this.showUser != null) {

        this.UserService.get(this.showUser).subscribe(res => {
          this.user = res;
          this.email =this.user.email;
          this.getMaterials();
        });
      }else {
        let e = JSON.parse(JSON.stringify(this.currentUser));
        this.UserService.findByEmail(e.email).subscribe(res => {
          this.user = res[0];
          this.email =this.user.email;
          this.getMaterials();
        });
      }
    }

    getDate() {
      let today = new Date();
      let day = '';
      const yyyy = today.getFullYear();
      let mm = String(today.getMonth() + 1).padStart(2, '0')
      let dd = String(today.getDate() + 1).padStart(2, '0')

      day = yyyy + '-' + mm + '-' + dd;
      return day;
    }


    moodleChecker() {
      //moodle check
      //console.log(this.user);
      //console.log(this.moodleMaterials);

      this.moodleCall().subscribe(res => {
        let list = res.value;

        list.map(item => {
          if (this.moodleMaterials.filter(e => e.url.indexOf(item.CourseId) !== -1).length > 0) {
            /* vendors contains the element we're looking for */

            let moodle = this.moodleMaterials.filter(e => e.url.indexOf(item.CourseId) !== -1)

            if(item.CompletionDate != null){
              let id = moodle[0].id;
              let record = {
                status: 'Completed',
                date_completed: item.CompletionDate.split('T')[0]
              }
              this.onBoardRecordService.update(id,record).subscribe(async res => {
                console.log('made this record:: ', res);
              });
            }
          }
        })
      });

    }

    moodleCall():Observable <any> {
      let user = this.user.email;

      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key': '5bcff4a8e64f4041a023ea29352106fc',
        'Authorization': `Bearer ${this.moodleToken.access_token}`
        //'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJodHRwczovL2FydXAub25taWNyb3NvZnQuY29tL0FJUyIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzRhZTQ4YjQxLTAxMzctNDU5OS04NjYxLWZjNjQxZmU3N2JlYS8iLCJpYXQiOjE2NjY0MDY4NTIsIm5iZiI6MTY2NjQwNjg1MiwiZXhwIjoxNjY2NDExOTg4LCJhY3IiOiIxIiwiYWlvIjoiQVRRQXkvOFRBQUFBbXh1c0U1RkhzUGhhSzlPUXZSZGNkN1ZYVGV6RkFkRTA2L3YrQUtLUTNQdkpHUHVNeHBmYlk4VHN6RStKR0FtNSIsImFtciI6WyJwd2QiXSwiYXBwaWQiOiIyOTNmNzExYy02ZDNjLTQxM2ItOTU5ZC1kMzFkN2IyMDU4NzgiLCJhcHBpZGFjciI6IjEiLCJmYW1pbHlfbmFtZSI6IlNWQy1BQUQiLCJnaXZlbl9uYW1lIjoiU1ZDLUFEUy1NYWpvcl9Qcm9qZWN0c190b29sIiwiZ3JvdXBzIjpbIjJmYzNkY2Y4LTY2Y2MtNDgwOS05NWU0LTg2Yjc5ZmRhYjgxNiIsIjNiYjYwZTgxLWM4YWQtNGIyNi1iYTU1LWE0NGIxZjYyNWU4OSIsImU3ODI1ZWE5LTI4YWQtNDU5Ni05NTE4LThiMmQyN2RhMGUwNiJdLCJpcGFkZHIiOiI0NS41OC4yNDUuMTg0IiwibmFtZSI6IlNWQy1BRFMtTWFqb3JfUHJvamVjdHNfdG9vbCIsIm9pZCI6IjYyNmNhODg4LWFjMmEtNGJiOC1iMmFhLTQ1NTkzYWU3NjU2YSIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS01MTU5Njc4OTktNDkyODk0MjIzLTcyNTM0NTU0My0xMjMzMzI3IiwicmgiOiIwLkFRVUFRWXZrU2pjQm1VV0dZZnhrSC1kNzZpNFNRbFpVNms1RWthT3M4bzZFN1VRRkFETS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJxZ3VGNFNkWkFLcWw1T0N4Q2R3c0J6dmRJQkZKT01SSWdKWlRneDZ1M05rIiwidGlkIjoiNGFlNDhiNDEtMDEzNy00NTk5LTg2NjEtZmM2NDFmZTc3YmVhIiwidW5pcXVlX25hbWUiOiJTVkMtQURTLU1ham9yX1Byb2plY3RzX3Rvb2xAYXJ1cC5vbm1pY3Jvc29mdC5jb20iLCJ1cG4iOiJTVkMtQURTLU1ham9yX1Byb2plY3RzX3Rvb2xAYXJ1cC5vbm1pY3Jvc29mdC5jb20iLCJ1dGkiOiJaMnpFZF9fcmZVV2xHYXZfMEpJQkFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXX0.Tja7YstsU1wyMTX_hIh9OPPf4p8Jd2o_95uIBFtD8K72El_DbZOhfozq5UJXrVvs4a5e72NR-Ms5kY2zp59_ymM-xGmV4EOZRIzVUziiAh1b-2iywL0U_BuBkGdXQpwr53cVp2gkElKsOBZZNTuiDHm_i5Zm4l5KlHrrfvp7J9qn_MpytAGR1AH3e62A1o_N1YuTLFaxbPFqcuP2_Bsr6vfv-PvAiR5Mai0eAqMk2RdWoSfnZjx0Z08LbwiqmnKg0q9nwkN8u2Cs6rByu1LKpaikmmceHBXO2n0kPm84ghccFgK5PW3xlVM3LVppvv2emjoZR67sSk3bIQ_Tbh6dig'
      }

      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

      //console.log(user, requestOptions);
      //console.log(this.moodleToken.access_token)
      let test = this.http.get(`https://adsprapiman.azure-api.net/ads/MoodleCourseCompletions?$filter=Upn eq '${user}'`, requestOptions);
      return test;
      //return;
    }

    moodleCall2(user):Observable <any> {
      //let user = this.user.email;

      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Ocp-Apim-Subscription-Key': '5bcff4a8e64f4041a023ea29352106fc',
        'Authorization': `Bearer ${this.moodleToken.access_token}`
        //'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJodHRwczovL2FydXAub25taWNyb3NvZnQuY29tL0FJUyIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzRhZTQ4YjQxLTAxMzctNDU5OS04NjYxLWZjNjQxZmU3N2JlYS8iLCJpYXQiOjE2NjY0MDY4NTIsIm5iZiI6MTY2NjQwNjg1MiwiZXhwIjoxNjY2NDExOTg4LCJhY3IiOiIxIiwiYWlvIjoiQVRRQXkvOFRBQUFBbXh1c0U1RkhzUGhhSzlPUXZSZGNkN1ZYVGV6RkFkRTA2L3YrQUtLUTNQdkpHUHVNeHBmYlk4VHN6RStKR0FtNSIsImFtciI6WyJwd2QiXSwiYXBwaWQiOiIyOTNmNzExYy02ZDNjLTQxM2ItOTU5ZC1kMzFkN2IyMDU4NzgiLCJhcHBpZGFjciI6IjEiLCJmYW1pbHlfbmFtZSI6IlNWQy1BQUQiLCJnaXZlbl9uYW1lIjoiU1ZDLUFEUy1NYWpvcl9Qcm9qZWN0c190b29sIiwiZ3JvdXBzIjpbIjJmYzNkY2Y4LTY2Y2MtNDgwOS05NWU0LTg2Yjc5ZmRhYjgxNiIsIjNiYjYwZTgxLWM4YWQtNGIyNi1iYTU1LWE0NGIxZjYyNWU4OSIsImU3ODI1ZWE5LTI4YWQtNDU5Ni05NTE4LThiMmQyN2RhMGUwNiJdLCJpcGFkZHIiOiI0NS41OC4yNDUuMTg0IiwibmFtZSI6IlNWQy1BRFMtTWFqb3JfUHJvamVjdHNfdG9vbCIsIm9pZCI6IjYyNmNhODg4LWFjMmEtNGJiOC1iMmFhLTQ1NTkzYWU3NjU2YSIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS01MTU5Njc4OTktNDkyODk0MjIzLTcyNTM0NTU0My0xMjMzMzI3IiwicmgiOiIwLkFRVUFRWXZrU2pjQm1VV0dZZnhrSC1kNzZpNFNRbFpVNms1RWthT3M4bzZFN1VRRkFETS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJxZ3VGNFNkWkFLcWw1T0N4Q2R3c0J6dmRJQkZKT01SSWdKWlRneDZ1M05rIiwidGlkIjoiNGFlNDhiNDEtMDEzNy00NTk5LTg2NjEtZmM2NDFmZTc3YmVhIiwidW5pcXVlX25hbWUiOiJTVkMtQURTLU1ham9yX1Byb2plY3RzX3Rvb2xAYXJ1cC5vbm1pY3Jvc29mdC5jb20iLCJ1cG4iOiJTVkMtQURTLU1ham9yX1Byb2plY3RzX3Rvb2xAYXJ1cC5vbm1pY3Jvc29mdC5jb20iLCJ1dGkiOiJaMnpFZF9fcmZVV2xHYXZfMEpJQkFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXX0.Tja7YstsU1wyMTX_hIh9OPPf4p8Jd2o_95uIBFtD8K72El_DbZOhfozq5UJXrVvs4a5e72NR-Ms5kY2zp59_ymM-xGmV4EOZRIzVUziiAh1b-2iywL0U_BuBkGdXQpwr53cVp2gkElKsOBZZNTuiDHm_i5Zm4l5KlHrrfvp7J9qn_MpytAGR1AH3e62A1o_N1YuTLFaxbPFqcuP2_Bsr6vfv-PvAiR5Mai0eAqMk2RdWoSfnZjx0Z08LbwiqmnKg0q9nwkN8u2Cs6rByu1LKpaikmmceHBXO2n0kPm84ghccFgK5PW3xlVM3LVppvv2emjoZR67sSk3bIQ_Tbh6dig'
      }

      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

      //console.log(user, requestOptions);
      //console.log(this.moodleToken.access_token)
      let test = this.http.get(`https://adsprapiman.azure-api.net/ads/MoodleCourseCompletions?$filter=Upn eq '${user}'`, requestOptions);
      return test;
      //return;
    }

    openDialog(type, title, body){
      const dialog = {
        title: title,
        body: body,
        type: type
      }
      this.DialogService.openDialog('',dialog);
    }

    getMaterials(){
      if(this.email == 'andrew.phillips-g@arup.com' && this.moodrun == true){
        this.usersSet = this.UserService.getAll().pipe(
          map( res => {
            return res
          })
        );

        this.usersSet.subscribe(r => {
          this.users = r;


          for(let i=0; i<this.users.length; i++) {
            this.moodleMaterials = [];
            this.onBoardRecordService.findByEmail(this.users[i].email).subscribe(res => {
              this.myMaterials = res;
              this.myMaterials.map(mat => { // capture moodle for examination
                if(mat.material_type == 'Moodle'){
                  this.moodleMaterials.push(mat);
                }
                mat.expiration_date = mat.expiration_date.split('T')[0];
                if(mat.last_updated != null)
                  mat.last_updated = mat.last_updated.split('T')[0];
              })


              setTimeout(() => {
                this.moodleCall2(this.users[i].email).subscribe(res => {
                  //if(this.users[i].email =='zak.kostura@arup.com'){
                    let list = res.value;
                    let freshMoods =[];
                    this.moodleMaterials.forEach((item, k) => {
                      if(item.email == this.users[i].email){
                        freshMoods.push(item);
                      }
                    });

                    list.map(item => {
                      if (freshMoods.filter(e => e.url.indexOf(item.CourseId) !== -1).length > 0) {
                        /* vendors contains the element we're looking for */

                        let moodle = freshMoods.filter(e => e.url.indexOf(item.CourseId) !== -1)

                        if(item.CompletionDate != null){
                          let id = moodle[0].id;
                          let record = {
                            status: 'Completed',
                            date_completed: this.getDate()
                          }
                          //console.log(this.users[i].email, id,record);
                          this.onBoardRecordService.update(id,record).subscribe(async res => {
                            console.log('made this record:: ', res);
                          });
                        }
                      }
                    })
                  //}
                });
              },3000);

            });
          }
        });
      }else{
        this.onBoardRecordService.findByEmail(this.email).subscribe(res => {
          this.myMaterials = res;
          this.myMaterials.map(mat => { // capture moodle for examination
            if(mat.material_type == 'Moodle'){
              this.moodleMaterials.push(mat);
            }
            mat.expiration_date = mat.expiration_date.split('T')[0];
            if(mat.last_updated != null)
              mat.last_updated = mat.last_updated.split('T')[0];
          })
          //console.log(this.myMaterials)
          this.dataSource = new MatTableDataSource<any>(res);
          this.getMaterialCount();
          this.dataSource.paginator = this.paginator;

          setTimeout(() => {
            this.moodleChecker();
          },3000);

        });
      }
    }

    getMaterialCount(){
      this.myMaterials.map(res => {
        if(res.status == "Completed") {
          this.totalCompleted++;
        }
      });
    }

    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }
}
