import { Component, Input, OnInit, Inject, Optional } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { MailService } from 'src/app/services/mail.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Users } from '../../types/user';
import { CompaniesService } from 'src/app/services/companies.service';
import { SubCompaniesService } from 'src/app/services/sub-companies.service';
import { PersonnelCategoryService } from 'src/app/services/personnel_category.service';
import { DisciplinesService } from 'src/app/services/disciplines.service';
import { DisciplineCategoryService } from 'src/app/services/discipline-category.service';
import { SpecialRoleService } from 'src/app/services/special-role.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { StatusService } from 'src/app/services/status.service';
import { StatusRecordsService } from 'src/app/services/status-records.service'
import { AlertService } from 'src/app/services/alert.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import moment from 'moment';


@Component({
  selector: 'app-adminUpdate',
  templateUrl: './admin-update.component.html',
  styleUrls: ['./admin-update.component.scss']
 })
export class AdminUpdateComponent implements OnInit {
  public currentUser: Observable<Users>;
  @Input() record: any;

  adminForm = this.formBuilder.group({});
  newRecord = null;

  displayedColumns: string[] = [];

  action:string;
  local_data:any;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private UserService: UserService,
      private StatusRecords: StatusRecordsService,
      private router: Router,
      private mail: MailService,
      private userService: UserService,
      private alertService: AlertService,
      private statusService: StatusService,
      private StatusRecordsService: StatusRecordsService,
      private DisciplinesService: DisciplinesService,
      private authenticationService: AuthenticationService,
      public dialogRef: MatDialogRef<AdminUpdateComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentUser = this.authenticationService.currentUserValue;

    this.action = data.action;

    Object.keys(data).map(res => {
      this.displayedColumns.push(res)
    })
    this.local_data = [{...data}];


  }


  doAction(){
    this.dialogRef.close({event:this.action,data:this.local_data[0]});
  }

  closeDialog(){
    this.dialogRef.close({event:'Cancel'});
  }

  async ngOnInit() {
  }

  getDate() {
    let today = new Date();
    let day = '';
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = 0 + dd;
    if (mm < 10) mm = 0 + mm;

    return day = yyyy + '-' + mm + '-' + dd;
  }
  dateAdjust(date) {
    return moment(date).format("YYYY-MM-DD");
  }

}
