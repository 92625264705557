import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { Status } from '../types/statuses';
import { environment } from '../../environments/environment';

const baseUrl = environment.apiUrl + '/statuses';

@Injectable({ providedIn: 'root' })
export class StatusService {
  constructor(private http: HttpClient) { }
  getAll(): Observable<Status[]> {
    return this.http.get<Status[]>(baseUrl);
  }
  get(id: any): Observable<any> {
    return this.http.get(`${baseUrl}/${id}`);
  }
  create(data: any): Observable<any> {
    return this.http.post(baseUrl, data);
  }
  update(id: any, data: any): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  }
  delete(id: any): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`);
  }
  deleteAll(): Observable<any> {
    return this.http.delete(baseUrl);
  }
  findByEmail(name: any): Observable<Status[]> {
    return this.http.get<Status[]>(`${baseUrl}?name=${name}`);
  }
}
