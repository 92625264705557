import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Users } from '../../types/user';


@Component({
  selector: 'app-support',
  templateUrl: 'support.component.html',
  styleUrls: ['./support.component.scss']
 })
export class SupportComponent implements OnInit {
    currentUser: Users;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        } else {
            this.currentUser = this.authenticationService.currentUserValue;
        }
    }

    ngOnInit() {

    }
}
