import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap, map } from 'rxjs/operators';
import { StatusRecordsService } from 'src/app/services/status-records.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Users } from '../../types/user';

@Component({
  selector: 'app-my-tasks',
  templateUrl: 'my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss']
 })
export class MyTasksComponent implements OnInit {
    currentUser: Users;
    users = [];
    userStatuses = [];

    constructor(
        private router: Router,
        private statusRecords: StatusRecordsService,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        } else {
            this.currentUser = this.authenticationService.currentUserValue;
        }
    }

    ngOnInit() {

      this.statusRecords.findByUserTags(this.currentUser['id']).subscribe(res => {

        res.map(r => {
          r.color = r.status.replace(/\s+/g, '');
          r.pack = r.package.toLowerCase() + '_package';
        })
        this.userStatuses = res;

      });
    }



}
